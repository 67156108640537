import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Classnames from 'classnames'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

const ImageElement = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  line-height: 0;
  padding: 0;
  position: absolute;
  top: ${props => props.position.includes('top') || (!props.position.includes('top') && !props.position.includes('bottom')) ? 0 : `auto`};
  left: ${props => props.position.includes('left') || (!props.position.includes('left') && !props.position.includes('right')) ? 0 : `auto`};
  right: ${props => props.position.includes('right') || (!props.position.includes('left') && !props.position.includes('right')) ? 0 : `auto`};
  bottom: ${props => props.position.includes('bottom') || (!props.position.includes('top') && !props.position.includes('bottom')) ? 0 : `auto`};
  margin: auto;
  transition: opacity .5s ease;
  opacity: ${props => props.visible ? 1 : 0};
  width: ${props => props.width}%;
  z-index: ${props => props.visible ? 1 : -1};

  img {
    max-height: 100%;
    object-fit: contain;
    object-position: ${props =>
      props.position.includes('top')
      ? css`50% 0%`
      : props.position.includes('left')
        ? css`0% 50%`
        : props.position.includes('right')
          ? css`100% 50%`
          : props.position.includes('bottom')
            ? css`50% 100%`
            : css`50% 50%`
    };
    width: 100%;
  }
`

class GraphicsImageElement extends PureComponent {
  element = React.createRef()

  render() {
    const { itemInTime, image, position, width } = this.props

    return (
      <ImageElement
        position={position}
        width={width}
        visible={itemInTime}>
        <img ref={this.element} src={image} />
      </ImageElement>
    )
  }

  static propTypes = {
    itemInTime: PropTypes.bool,
    image: PropTypes.string,
    position: PropTypes.string,
    width: PropTypes.number
  }

  static defaultProps = {
    itemInTime: false,
    image: '',
    position: '',
    width: undefined
  }
}

export default GraphicsImageElement
