import React, { PureComponent } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle, css } from 'styled-components'
import styles from 'styles'

import LayoutContext from 'components/Layout/LayoutContext'
import WindowDimensionsContext from 'components/WindowDimensionsContext'

import Header from './Header'
import BackgroundImage from 'components/Layout/BackgroundImage'
import Button from 'components/elements/Button'

import Animation from 'components/Animation'

const GlobalStyles = createGlobalStyle`
  html, body {
    background-color: ${styles.darkBlue};
    font-family: ${styles.bodyFontFamily};
    font-weight: ${styles.bodyFontWeight};
    font-style: normal;
    font-size: ${styles.baseSize.desktop};
    line-height: 1.4em;
    height: 100vh;
    padding: 0;
    margin: 0;
    width: 100%;

    ${props => props.breakpoint.tablet && css`
      font-size: ${styles.baseSize.tablet};
    `}

    ${props => props.breakpoint.mobile && css`
      font-size: ${styles.baseSize.mobile};
      max-height: -webkit-fill-available;
    `}

    ${props => props.fixed && css`
      height: 100vh;
      overflow: hidden;
      position: fixed;
      width: 100vw;
    `}

    h1, h2, h3 {
      font-family: ${styles.headingFontFamily};
      font-weight: ${styles.headingFontWeight};
      letter-spacing: 0.01em;
      line-height: 1.6em;
      text-transform: uppercase;
    }

    h1 {
      font-size: 1.6em;

      ${props => !props.breakpoint.desktop && css`
        font-size: 1.4em;
      `}
    }

    button {
      font-family: ${styles.bodyFontFamily};
      font-weight: ${styles.bodyFontWeight};
    }

    #root {
      min-height: 100%;
      min-width: 100%;
    }
  }
`

const LayoutContainer = styled.div`
  min-height: 100%;
  min-width: 100%;
`

const Backdrop = styled.div`
  background-color: ${styles.darkBlue};
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
`

class Layout extends PureComponent {
  state = {
    visible: false
  }

  static contextType = WindowDimensionsContext

  componentDidMount() {
    const { location } = this.props

    if (location.pathname === '/' || location.pathname === '/en' || location.pathname === '/kontakt' || location.pathname === '/en/contact') {
      setTimeout(() => {
        this.setState({visible: true})
      }, 750)
    } else {
      this.setState({visible: true})
    }
  }

  render() {
    const { history } = this.props
    const { visible } = this.state
    const { project } = this.context

    const { breakpoint } = this.context

    return (
      <LayoutContext.Consumer>
        {context => {
          const { backgroundImage, currentView, isFixedLayout, language, animateProject } = context

          return (
            <>
              <GlobalStyles breakpoint={breakpoint} fixed={isFixedLayout} />

              <LayoutContainer>
                {visible !== true && (
                  <Backdrop />
                )}

                <BackgroundImage fixedHeight src={backgroundImage} breakpoint={breakpoint} />

                <Header breakpoint={breakpoint} view={{}} />

                {this.props.children}

                <footer />
              </LayoutContainer>
            </>
          )
        }}
      </LayoutContext.Consumer>
    )
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  static defaultProps = {
    match: {},
    location: {},
    history: {}
  }
}

export default withRouter(Layout)
