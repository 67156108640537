import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import { useVideoContext } from 'components/Project/VideoContext'
import { VideoReplayIcon } from 'components/common/vectors'

const Container = styled.div`
  cursor: pointer;
  display: block;
  height: 4em;
  position: fixed;
  left: 0;
  bottom: ${props => props.fadeOut ? css`-10vw` : 0};
  transform: scale(.75);
  transition: bottom 1s 1000ms;
  width: 4em;
  z-index: 1999;

  .svg {
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 40%;
  }
`

const TimelinePlayPauseButton = memo((props) => {
  const { fadeOut } = props

  return (
    <Container fadeOut={fadeOut} onClick={props.onPlay}>
      <VideoReplayIcon playButton={props.paused} />
    </Container>
  )
})

TimelinePlayPauseButton.propTypes = {
  onPlay: PropTypes.func
}

TimelinePlayPauseButton.defaultProps = {
  onPlay: function() {}
}

export default TimelinePlayPauseButton
