import React, { PureComponent } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import Image from './Image'

const ImageWrapper = styled.div`
  background-color: ${styles.darkBlue};
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  ${props => props.breakpoint.mobile && props.fixedHeight && css`
    height: 150vw;
  `}

  &::after {
    /* background-color: ${styles.blue};
    opacity: .35; */
    background-color: ${styles.darkBlue};
    opacity: .5;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`

class BackgroundImage extends PureComponent {
  state = {
    imagePreloadSrc: undefined,
    imageSrc: []
  }

  componentDidMount() {
    let { src } = this.props
    const { breakpoint } = this.props
    let { imageSrc } = this.state

    if (breakpoint.mobile && src) src = src.replace('.jpg', '_mobile.jpg')

    this.setState({imageSrc: [...imageSrc, src]})
  }

  componentDidUpdate(prevProps, prevState) {
    let { src } = this.props

    if (src !== prevProps.src) {
      const { breakpoint } = this.props
      let { imageSrc } = this.state

      if (breakpoint.mobile && src) src = src.replace('.jpg', '_mobile.jpg')

      this.setState({imageSrc: [...imageSrc, src]})
    }
  }

  render() {
    const { breakpoint, fixedHeight } = this.props
    const { imageSrc } = this.state

    let imageCount = 2

    return (
      <ImageWrapper breakpoint={breakpoint} fixedHeight={fixedHeight}>
        {imageSrc.map((src, index) => {
          return (
            <Transition
              in={index >= (imageSrc.length-imageCount)}
              timeout={{
                enter: 0,
                exit: 500
              }}
              appear
              unmountOnExit>
              {state => {
                return (
                  // state change: exited -> entering -> entered -> exiting -> exited
                  <Image key={index} src={src} />
                )
              }}
            </Transition>
          )
        })}
      </ImageWrapper>
    )
  }

  static propTypes = {
    breakpoint: PropTypes.object,
    fixedHeight: PropTypes.bool,
    src: PropTypes.string
  }

  static defaultProps = {
    breakpoint: {},
    fixedHeight: false,
    src: ''
  }
}

export default BackgroundImage
