import config from 'config'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styles, { styled, css } from 'styles'
import { keyframes } from 'styled-components'

const Container = styled.div`
  display: flex;
  width: 100%;

  &:not(:last-child) {
    margin-right: 3.25vw;

    ${props => props.animate && css`
      margin-right: 0;
      transition: margin-right .25s ease 500ms;
    `}
  }

  ${props => !props.breakpoint.mobile && css`
    flex-direction: column;
  `}

  ${props => props.breakpoint.mobile && css`
    align-items: center;
    justify-content: space-evenly;
  `}
`

const Image = styled.div`
  padding-bottom: 2vw;
  overflow: hidden;
  text-align: center;

  ${props => !props.breakpoint.mobile && css`
    border-bottom: 1px solid ${styles.orange};
  `}

  ${props => props.breakpoint.mobile && css`
    flex-basis: 50%;
  `}

  img {
    bottom: ${props => props.animate ? css`-300px` : css`0`};
    height: ${props => props.breakpoint.desktop ? css`${11.5*props.aspectRatio}em` : css`${8.5*props.aspectRatio}em`};
    position: relative;
    margin: auto;
    width: ${props => props.breakpoint.desktop ? css`11.5em` : css`8.5em`};
    opacity: ${props => props.animate ? 0 : 1};
    transition:
      bottom .75s ease,
      opacity .75s ease;
  }
`

const ParagraphContainer = styled.div`
  padding-top: 2vw;
  margin: 0;
  overflow: hidden;

  ${props => props.breakpoint.mobile && css`
    flex-basis: 50%;
  `}
`

const Paragraph = styled.p`
  font-size: .9em;
  font-weight: ${styles.bodyFontWeightLight};
  position: relative;
  top: ${props => props.animate ? css`-300px` : css`0`};
  margin: 0;
  opacity: ${props => props.animate ? 0 : 1};
  transition:
    top .75s ease,
    opacity .75s ease;
  white-space: nowrap;

  strong {
    font-weight: 500;
  }

  a {
    color: ${styles.white};
    text-decoration: none;
  }
`

const Member = memo(props => {
  const { breakpoint, image, name, role, email, phone, view } = props

  return (
    <Container animate={config.enableDesktopAnimations && breakpoint.desktop && view.exit} breakpoint={breakpoint}>
      <Image animate={config.enableDesktopAnimations && breakpoint.desktop && view.exit} aspectRatio={1.1691} breakpoint={breakpoint}>
        <img src={image} />
      </Image>

      <ParagraphContainer breakpoint={breakpoint}>
        <Paragraph animate={config.enableDesktopAnimations && breakpoint.desktop && view.exit}>
          <strong>{name}</strong><br />
          {role}<br /><br />
          <a href={`mailto:${email}`}>{email}</a><br />
          <a href={`tel:${phone}`}>{phone}</a>
        </Paragraph>
      </ParagraphContainer>
    </Container>
  )
})

Member.propTypes = {
  breakpoint: PropTypes.object,
  image: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string
}

Member.defaultProps = {
  breakpoint: function() {},
  image: '',
  name: '',
  role: '',
  email: '',
  phone: ''
}

export default Member
