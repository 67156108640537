import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import { AudioIcon } from 'components/common/vectors'

import { useVideoContext } from 'components/Project/VideoContext'

const Container = styled.div`
  cursor: pointer;
  display: block;
  height: 4em;
  position: fixed;
  right: 0;
  bottom: ${props => props.fadeOut ? css`-10vw` : 0};
  overflow: hidden;
  touch-action: manipulation;
  transform: scale(.75);
  transition: bottom 1s 1000ms;
  width: 4em;
  z-index: 1999;

  &::before,
  &::after {
    background-color: ${styles.white};
    content: "";
    height: 30%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: all .15s ease;
    width: 2px;
  }

  ${props => !props.muted && css`
    &::before {
      height: 15%;
      left: 25%;
    }

    &::after {
      height: 40%;
      left: 50%;
    }
  ` || css`
    &::before {
      left: 40%;
      transform: rotate(-45deg);
    }

    &::after {
      left: 40%;
      transform: rotate(45deg);
    }
  `}

  .svg {
    height: 50%;
    position: absolute;
    top: 0;
    left: 15%;
    bottom: 0;
    margin: auto;
    width: 35%;
  }
`

const MuteButton = memo((props) => {
  const { breakpoint, fadeOut } = props

  return (
    <Container breakpoint={breakpoint} fadeOut={fadeOut} muted={props.muted} onClick={props.onClick}>
      <AudioIcon />
    </Container>
  )
})

MuteButton.propTypes = {
  breakpoint: PropTypes.object,
  muted: PropTypes.bool,
  onClick: PropTypes.func
}

MuteButton.defaultProps = {
  breakpoint: {},
  muted: true,
  onClick: function() {}
}

export default MuteButton
