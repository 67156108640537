import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styles, { styled, css } from 'styles'

const TextareaElement = styled.textarea`
  background-color: ${styles.darkerBlue};
  border: 1px solid ${styles.white};
  border-radius: 0;
  box-sizing: border-box;
  color: ${styles.white};
  font-family: ${styles.bodyFontFamily};
  font-size: .8em;
  outline: none;
  padding: 1em;
  margin-bottom: .75vw;
  width: 100%;

  &::placeholder {
    color: ${styles.white};
  }

  ${props => props.value === null && css`
    border-color: ${styles.red};
  `}

  ${props => props.css}
`

const Textarea = memo((props) => {
  return (
    <TextareaElement
      css={props.css}
      type="text"
      placeholder={props.placeholder}
      rows={5}
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)} />
  )
})

Textarea.propTypes = {
  css: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

Textarea.defaultProps = {
  css: '',
  placeholder: '',
  value: '',
  onChange: function() {}
}

export default Textarea
