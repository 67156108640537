import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import styles from 'styles/helper'

import { useLayoutContext } from 'components/Layout/LayoutContext'

const fadeIn = (props) => keyframes`
  0% {
    opacity: 0;
    width: 0%;
  }
  1% {
    opacity: 0;
    width: 0%;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    width: ${props.widthPercentage}%;
  }
`

const fadeOut = (props) => keyframes`
  0% {
    opacity: 1;
    width: ${props.widthPercentage}%;
  }
  50% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    width: 0%;
  }
  100% {
    opacity: 0;
    width: 0%;
  }
`

const AnimationContainer = styled.div`
  animation: 1s ${props => props.changing ? fadeOut(props) : fadeIn(props)} ease;
  float: ${props => props.changing ? css`right` : css`left`};
  position: relative;
  opacity: ${props => props.changing ? 0 : 1};
  overflow: ${props => props.changing ? css`hidden` : css`visible`};
  white-space: nowrap;
  width: ${props => props.changing ? css`0%` : css`${props.widthPercentage}%`};

  &::before {
    border: 1px solid ${styles.white};
    box-sizing: border-box;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
  }

  button {
    float: right;
    transition:
      background-color .25s ease,
      transform .25s ease;
  }

  .arrow {
    position: absolute;
    top: 0;
    right: 1.5em;
    bottom: 0;
    margin: auto;
  }

  ${props => props.css}
`

const Animation = memo(props => {
  const { changing, css, widthPercentage } = props

  return (
    <AnimationContainer
      changing={changing}
      css={css}
      widthPercentage={widthPercentage}>
      {props.children}
    </AnimationContainer>
  )
})

Animation.propTypes = {
  changing: PropTypes.bool,
  css: PropTypes.string,
  widthPercentage: PropTypes.number
}

Animation.defaultProps = {
  changing: false,
  css: '',
  widthPercentage: PropTypes.number
}

export default Animation
