import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useCountUp } from 'react-countup'
import styled, { css, keyframes } from 'styled-components'
import styles from 'styles/helper'

import TransitionAnimation from 'components/TransitionAnimation'

const progress = (props) => keyframes`
  0% { width: 0%; }
  10% { width: 50%; }
  20% { width: 75%; }
  30% { width: 87.5%; }
  40% { width: 92.5%; }
  50% { width: 95%; }
  70% { width: 97%; }
  100% { width: 98%; }
`

const LoadingContainer = styled.div`
  color: white;
  font-size: 50px;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  overflow: hidden;
  width: 50vw;
  z-index: 10000;
`

const Percentage = styled.div`
  height: 50px;
  line-height: 1.1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${props => props.animate ? css`-100px` : css`50px`};
  margin: auto;
  opacity: ${props => props.animate ? 0 : 1};
  transition:
    bottom 1s ease 250ms,
    opacity .25s ease 250ms;
  width: 50%;
`

const ProgressBar = styled.div`
  border-radius: 2px;
  height: 5px;
  position: relative;
  overflow: hidden;
  width: 100%;

  &::before {
    background-color: ${styles.white};
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`

const ProgressIndicator = styled.div`
  background-color: ${styles.orange};
  content: "";
  display: block;
  height: 5px;
  position: absolute;
  bottom: 0;
  width: ${props => props.loaded ? css`100%` : css`98%`};

  ${props => !props.loaded && css`
    animation: 5s ${progress(props)} linear;
  `}
`

const Loading = memo((props) => {
  const { loaded } = props

  let { countUp } = useCountUp({
    duration: 5,
    end: 98,
    suffix: '%'
  })

  return (
    <LoadingContainer>
      <Percentage animate={props.animate}>
        {!loaded ? countUp : '100%'}
      </Percentage>

      <TransitionAnimation exit animateOut={props.animate} css={css`
        border-radius: 2px;
        height: 5px;
        position: absolute;
        bottom: 0;
        width: 100%;
      `}>
        <ProgressBar>
          <ProgressIndicator loaded={loaded} />
        </ProgressBar>
      </TransitionAnimation>
    </LoadingContainer>
  )
})

Loading.propTypes = {
  loaded: PropTypes.bool
}

Loading.defaultProps = {
  loaded: false
}

export default Loading
