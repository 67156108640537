import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import { NavLink } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import { withView } from 'components/Layout/LayoutContext'

import Arrow from 'components/elements/Arrow'
import LayoutContext from 'components/Layout/LayoutContext'
import TransitionAnimation from 'components/TransitionAnimation'
import HomeContent from 'components/Home/HomeContent'
import HomeProject from 'components/Home/HomeProject'
import LanguageSwitcher from 'components/Home/LanguageSwitcher'

const HomeContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow-x: hidden;
  transition: background .15s ease;
  width: 100%;

  ${props => !props.breakpoint.mobile && css`
    position: fixed;
  `}

  ${props => props.breakpoint.mobile && css`
    flex-direction: column;
    padding-top: 30vw;
    position: relative;
  `}
`

const Projects = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 30%;

  ${props => props.breakpoint.desktop && css`
    margin-left: 10vw;
  `}

  ${props => props.breakpoint.tablet && css`
    margin-top: 5vw;
    margin-left: 5vw;
    width: 40%;
  `}

  ${props => props.breakpoint.mobile && css`
    order: 1;
    position: relative;
    width: 100vw;

    &::before {
      border-left: 1px solid white;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: -25vw;
      left: 0;
      right: 0;
      margin: auto;
      width: 90vw;
      z-index: 1000;
    }

    &::after {
      border-right: 1px solid white;
      content: "";
      display: block;
      height: calc(100% - 25vh);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 90vw;
      z-index: 1000;
    }
  `}
`

const ScrollIndicator = styled.div`
  color: ${styles.white};
  font-size: .8em;
  padding-top: 7.5vw;
  position: absolute;
  top: -25vw;
  left: 10vw;
  text-transform: uppercase;
`

const Footer = styled.div`
  color: ${styles.white};
  font-size: .9em;
  font-weight: 300;
  margin-bottom: 2em;
  order: 2;
  text-align: center;

  span {
    display: block;

    a {
      color: ${styles.white};
      text-decoration: none;
    }
  }
`

class Home extends PureComponent {
  state = {
    categoryIndex: undefined,
    activeCategoryIndex: 0,
    activeProjectIndex: undefined,
    backgroundImageSrc: undefined,
    timeout: undefined,
    hideElements: false,
    visible: false
  }

  project = React.createRef()

  static contextType = LayoutContext

  componentDidMount() {
    const { breakpoint } = this.props
    const { categories } = this.context

    this.setState({
      backgroundImageSrc: !breakpoint.mobile
        ? categories[0].projects[0].backgroundImage
        : categories[0].backgroundImage
    })

    this.loopProjects()
  }

  componentDidUpdate(prevProps, prevState) {
    this.mapContextToState()

    const { breakpoint } = this.props
    const { categoryIndex } = this.state
    const { categories } = this.context

    if (!isNaN(categoryIndex) && categoryIndex !== prevState.categoryIndex) {
      this.hideElements()

      setTimeout(() => {
        if (breakpoint.mobile) {
          this.setState({
            activeCategoryIndex: categoryIndex,
            backgroundImageSrc: categories[categoryIndex].backgroundImage
          })
        } else {
          this.setState({
            activeCategoryIndex: categoryIndex,
          })
        }

        this.showElements()
      }, prevState.categoryIndex === undefined ? 0 : 1500)

      this.endProjectsLoop()
      this.setState({activeProjectIndex: -1},
        () => this.loopProjects()
      )
    }

    const { activeProjectIndex } = this.state

    if (activeProjectIndex >= 0 && activeProjectIndex !== prevState.activeProjectIndex) {
      const { activeCategoryIndex } = this.state
      const activeCategory = categories[activeCategoryIndex]

      if (!breakpoint.mobile) {
        this.setState({
          backgroundImageSrc: activeCategory.projects[activeProjectIndex].backgroundImage
        })
      }
    }

    let { backgroundImageSrc } = this.state

    if (backgroundImageSrc !== prevState.backgroundImageSrc) {
      const { setBackgroundImage } = this.context

      setBackgroundImage(backgroundImageSrc)
    }
  }

  mapContextToState() {
    const { categoryIndex } = this.context

    if (categoryIndex !== this.state.categoryIndex) this.setState({categoryIndex})
  }

  componentWillUnmount() {
    this.endProjectsLoop()
  }

  loopProjects() {
    const { breakpoint } = this.props

    if (breakpoint.mobile) return false

    let { activeProjectIndex, timeout } = this.state
    const { categories, setCategoryIndex } = this.context

    clearTimeout(timeout)

    timeout = setTimeout(() => {
      let { activeProjectIndex } = this.state
      const { categoryIndex } = this.context
      const activeCategory = categories[categoryIndex]

      const hasNextProject = activeCategory.projects.length-1 > activeProjectIndex || activeProjectIndex === undefined

      if (hasNextProject) {
        if (isNaN(activeProjectIndex)) activeProjectIndex = -1

        this.setState({activeProjectIndex: activeProjectIndex+1}, () => this.loopProjects())
      } else {
        const hasNextCategory = categories.length-1 > categoryIndex

        setCategoryIndex(hasNextCategory ? categoryIndex+1 : 0)
      }
    }, activeProjectIndex === undefined ? 2000 : activeProjectIndex === -1 ? 2500 : 5000)

    this.setState({timeout})
  }

  endProjectsLoop() {
    const { timeout } = this.state

    clearTimeout(timeout)
  }

  hideElements() {
    this.setState({hideElements: true})
  }

  showElements() {
    this.setState({hideElements: false})
  }

  render() {
    const { breakpoint } = this.props
    const { activeCategoryIndex, activeProjectIndex, hideElements, visible } = this.state
    const { categories, categoryIndex, language, getTranslation, languageIsChanging, view } = this.context
    const activeCategory = categories[activeCategoryIndex]
    const activeProject = activeCategory.projects[activeProjectIndex]

    return (
      <>
        <Transition
          in={visible !== true}
          timeout={750}
          onEntered={() => {
            if (visible !== true) this.setState({visible: true})
          }}
          appear
          unmountOnExit>
          {state => {
            return (
              // state change: exited -> entering -> entered -> exiting -> exited
              <TransitionAnimation animateIn={state.includes('enter')} animateOut={state.includes('exit')} overlay={true} />
            )
          }}
        </Transition>

        {visible && (
          <HomeContainer breakpoint={breakpoint} visible={visible}>
            <Projects breakpoint={breakpoint}>
              {/*breakpoint.mobile && (
                <ScrollIndicator>
                  Vaata projekte

                  <Arrow height={14} css={css`cursor: pointer; display: block; margin: auto; transform: rotate(90deg) scale(.8);`} />
                </ScrollIndicator>
              )*/}

              {activeCategory.projects.map((project, index) => {
                return (
                  <HomeProject
                    activeCategory={activeCategory}
                    activeProjectIndex={activeProjectIndex}
                    breakpoint={breakpoint}
                    hideElements={hideElements}
                    project={project}
                    index={index}
                    onMouseEnter={(e) => this.setState({activeProjectIndex: index}, () => this.endProjectsLoop())}
                    onMouseLeave={(e) => view.entered ? this.loopProjects() : undefined} />
                )
              })}
            </Projects>

            <HomeContent breakpoint={breakpoint} currentTime={categoryIndex} stops={categories} language={language} languageIsChanging={languageIsChanging} visible={visible} view={view} />

            {!breakpoint.mobile && <LanguageSwitcher breakpoint={breakpoint} view={view} />}

            {breakpoint.mobile && (
              <Footer breakpoint={breakpoint}>
                <span><a href="mailto:info@rockstick.ee">info@rockstick.ee</a></span>
                <span><a href="tel:+372 5904 5001">+372 5904 5001</a></span>
                <span>{getTranslation('contact.location')}</span>
              </Footer>
            )}
          </HomeContainer>
        )}
      </>
    )
  }

  static propTypes = {}

  static defaultProps = {}
}

export default withView(Home)
