import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import LayoutContext from 'components/Layout/LayoutContext'
import VideoContext from 'components/Project/VideoContext'
import Content from 'components/Content'

const ContentWrapper = styled.div`
  background-image: linear-gradient(to right, rgba(${styles.rgb.blue}, .4), transparent);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: ${props => props.isHidden ? 0 : 1};
  transition: opacity .5s ease;
  width: 40vw;
  z-index: 1001;

  ${props => !props.breakpoint.desktop && css`
    width: 100vw;
  `}
`

const ContentContainer = styled.div`
  position: relative;
  margin-left: ${props => !props.breakpoint.mobile ? `2em` : `1em`};
  margin-right: ${props => !props.breakpoint.mobile ? `2em` : `1em`};
  width: 100%;
`

class ProjectContent extends PureComponent {
  state = {
    hidden: false
  }

  static contextType = VideoContext

  componentDidUpdate(prevProps, prevState) {
    const { fadeOut } = this.props

    if (fadeOut && fadeOut !== prevProps.fadeOut) {
      setTimeout(() => {
        this.setState({hidden: true})
      })
    }
  }

  render() {
    const { breakpoint, stops, hide, fadeOut } = this.props
    const { hidden } = this.state
    const { currentTime, ended } = this.context

    return (
      <LayoutContext.Consumer>
        {context => {
          const { language } = context

          return (
            <ContentWrapper breakpoint={breakpoint} isHidden={ended}>
              {!hide && (
                <ContentContainer breakpoint={breakpoint}>
                  <Content breakpoint={breakpoint} currentTime={currentTime} stops={!hidden ? stops : []} language={language} fadeOut={fadeOut} hidden={hidden} />
                </ContentContainer>
              )}
            </ContentWrapper>
          )
        }}
      </LayoutContext.Consumer>
    )
  }

  static propTypes = {
    breakpoint: PropTypes.object,
    stops: PropTypes.array
  }

  static defaultProps = {
    breakpoint: {},
    stops: []
  }
}

export default ProjectContent
