import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import { useVideoContext } from 'components/Project/VideoContext'

const PlayPauseButton = styled.div`
  cursor: pointer;
  display: block;
  height: 4em;
  position: fixed;
  left: 0;
  bottom: ${props => props.fadeOut ? css`-10vw` : 0};
  touch-action: manipulation;
  transform: scale(.75);
  transition: bottom 1s 1000ms;
  width: 4em;
  z-index: 1999;
`

const PlayPauseIcon = styled.div`
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  transition: border-radius .15s ease;
  width: 25%;

  ${props => props.breakpoint.tablet && css`
    left: 7.5%;
  `}

  &::before {
    border-left: 2px solid ${styles.white};
    border-right: 2px solid ${styles.white};
    box-sizing: border-box;
    content: "";
    height: 80%;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
    transition: all .15s ease;
  }

  &::after {
    border: 2px solid ${styles.white};
    border-radius: 2px 4px;
    box-sizing: border-box;
    content: "";
    height: 75%;
    width: 150%;
    position: absolute;
    top: 0;
    left: -150%;
    bottom: 0;
    margin: auto;
    opacity: 0;
    transform: rotate(45deg);
    transition: all .15s ease;
  }

  ${props => props.playButton && css`
    border-left: 2px solid ${styles.white};
    border-radius: 2px;

    &::after {
      left: -75%;
      opacity: 1;
    }
  ` || css`
    &::before {
      opacity: 1;
      width: 75%;
    }
  `}
`

const TimelinePlayPauseButton = memo((props) => {
  const onToggle = () => {
    if (props.paused) {
      props.onPlay()
    } else {
      props.onPause()
    }
  }

  const { breakpoint, fadeOut } = props

  return (
    <PlayPauseButton breakpoint={breakpoint} fadeOut={fadeOut} onClick={onToggle}>
      <PlayPauseIcon breakpoint={breakpoint} playButton={props.paused} />
    </PlayPauseButton>
  )
})

TimelinePlayPauseButton.propTypes = {
  breakpoint: PropTypes.object,
  paused: PropTypes.bool,
  onPlay: PropTypes.func,
  onPause: PropTypes.func
}

TimelinePlayPauseButton.defaultProps = {
  breakpoint: {},
  paused: false,
  onPlay: function() {},
  onPause: function() {}
}

export default TimelinePlayPauseButton
