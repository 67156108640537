import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import { ArrowIcon } from 'components/common/vectors'

const ArrowContainer = styled.div`
  display: inline-block;
  height: ${props => props.height}px;
  position: relative;
  overflow: hidden;
  width: ${props => props.height/1.5}px;

  .arrow-icon {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;

    ${props => props.color && css`
      path {
        stroke: ${props.color};
      }
    `}
  }

  ${props => props.css}
`

const Arrow = memo((props) => {
  const { css, color, height } = props

  return (
    <ArrowContainer className="arrow" css={css} color={color} height={height} onClick={props.onClick}>
      <ArrowIcon className="arrow-icon" />
    </ArrowContainer>
  )
})

Arrow.propTypes = {
  css: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  onClick: PropTypes.func,
  white: PropTypes.bool
}

Arrow.defaultProps = {
  css: '',
  color: 'white',
  height: 20,
  onClick: function() {},
  white: false
}

export default Arrow
