import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import PropTypes from 'prop-types'
import styles, { styled, css } from 'styles'

import LayoutContext from 'components/Layout/LayoutContext'
import TransitionAnimation from 'components/TransitionAnimation'

import Members from './Members/members'
import Form from './Form'

import { VideoNextBroadcast } from 'components/common/vectors'

const ContactWrapper = styled.div`
  background-color: ${styles.darkBlue};
  background-image: linear-gradient(to right, ${styles.darkBlue}, ${styles.darkerBlue});
  box-sizing: border-box;
  height: 100%;
  visibility: ${props => props.visible ? css`visible` : css`hidden`};
  width: 100%;
  z-index: 1001;

  ${props => props.breakpoint.desktop && css`
    position: fixed;
    top: 0;
    left: 0;
  `}

  ${props => !props.breakpoint.desktop && css`
    padding: 20vh 0;
    position: relative;
    min-height: 100vh;
  `}
`

const Exit = styled.div`
  cursor: pointer;
  height: 2.5em;
  line-height: 0;
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  width: 2.5em;

  svg {
    transform: scaleX(-1);
  }

  ${props => props.focus && css`
    svg {
      .svg-fill {
        fill: ${styles.orange};
        transition: fill .25s ease;
      }

      .svg-stroke {
        stroke: ${styles.orange};
        transition: stroke .25s ease;
      }
    }
  `}
`

const ContactContainer = styled.div`
  color: white;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${props => !props.breakpoint.mobile && css`
    align-content: center;
  `}
`

const SocialMediaIcons = styled.div`
  line-height: 0;
  position: absolute;
  left: ${props => !props.breakpoint.mobile ? css`1.5em` : css`1em`};
  bottom: ${props => !props.breakpoint.mobile ? css`1.5em` : css`.75em`};

  a {
    &:not(:last-child) {
      margin-right: .5em;
    }

    img {
      height: 1.75em;
      width: 1.75em;
    }
  }
`

const ContactFooter = styled.div`
  color: white;
  font-size: .9em;
  font-weight: 300;
  line-height: 1;
  position: absolute;
  right: ${props => !props.breakpoint.mobile ? css`1.5em` : css`1em`};
  bottom: ${props => !props.breakpoint.mobile ? css`1.5em` : css`.75em`};

  img {
    height: 1.75em;
    width: 1.75em;
  }

  span {
    display: inline-block;
    position: relative;
    top: -.5em;
    margin-left: .25em;
  }
`

class Contact extends PureComponent {
  state = {
    animating: undefined,
    emailSent: false,
    visible: false
  }

  static contextType = LayoutContext

  render() {
    const { breakpoint } = this.props
    const { animating, emailSent, visible } = this.state
    const { team, language, getTranslation } = this.context

    const view = { // TODO: This can be moved over to LayoutContext
      state: this.props.transitionState,
      enter: this.props.transitionState === 'entered' && animating,
      exit: this.props.transitionState !== 'entered'
    }

    return (
      <>
        <Transition
          in={visible === false}
          timeout={750}
          onEnter={() => this.setState({animating: true})}
          onEntered={() => {
            if (visible === false) this.setState({visible: true})
          }}
          onExited={() => this.setState({animating: false})}
          appear
          unmountOnExit>
          {state => {
            return (
              // state change: exited -> entering -> entered -> exiting -> exited
              <TransitionAnimation animateIn={state.includes('enter')} animateOut={state.includes('exit')} overlay={true} />
            )
          }}
        </Transition>

        <ContactWrapper breakpoint={breakpoint} visible={visible}>
          {!breakpoint.mobile && visible && (
            <Exit focus={emailSent}>
              <Transition
                in={view.state === 'entered'}
                timeout={750}
                appear>
                {state => {
                  return (
                    // state change: exited -> entering -> entered -> exiting -> exited
                    <TransitionAnimation state={state}
                      // enter={view.enter}
                      exit={view.exit}>
                      <NavLink to={language === 'en' ? `/en` : `/`}>
                        <VideoNextBroadcast strokeWidth="1" />
                      </NavLink>
                    </TransitionAnimation>
                  )
                }}
              </Transition>
            </Exit>
          )}

          {/*<h2 className="title">{getTranslation('contact')}</h2>*/}

          <ContactContainer breakpoint={breakpoint}>
            {visible && <Members breakpoint={breakpoint} team={team} language={language} view={view} />}

            {visible && <Form breakpoint={breakpoint} emailSent={emailSent} onSubmit={() => this.setState({emailSent: true})} view={view} />}
          </ContactContainer>

          {visible && (
            <SocialMediaIcons breakpoint={breakpoint}>
              <a href="https://www.facebook.com/rockstickproduction" target="_blank">
                <img src="/facebook.svg" />
              </a>

              <a href="https://www.youtube.com/rockstickproduction" target="_blank">
                <img src="/youtube.svg" />
              </a>

              <a href="https://www.instagram.com/rockstickproduction" target="_blank">
                <img src="/instagram.svg" />
              </a>
            </SocialMediaIcons>
          )}

          {visible && (
            <ContactFooter breakpoint={breakpoint}>
              {/*<img src="/location.svg" />*/}

              <span>{getTranslation('contact.location')}</span>
            </ContactFooter>
          )}
        </ContactWrapper>
      </>
    )
  }

  static propTypes = {}

  static defaultProps = {}
}

export default Contact
