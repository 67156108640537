import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Classnames from 'classnames'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import { isFunction } from 'helpers/typeHelper'

const ButtonContainer = styled.button`
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
  color: ${styles.white};
  cursor: pointer;
  font-size: 1em;
  outline: none;
  padding: ${props => !props.breakpoint.mobile ? css`1em` : css`.5em`};
  position: relative;
  margin: auto;
  text-transform: uppercase;
  transition:
    background-color .15s ease,
    transform .15s ease;
  width: auto;

  ${props => props.accent && css`
    background-color: ${styles.orange};
    color: ${styles.darkBlue};

    .svg-fill {
      fill: ${styles.darkBlue};
    }

    .svg-stroke {
      stroke: ${styles.darkBlue};
    }
  `}

  ${props => props.active && css`
    background-color: ${styles.darkBlue};
    color: ${styles.orange};
  `}

  ${props => props.bordered && css`
    border: 1px solid white;
  `}

  ${props => props.hover && css`
    background-color: ${styles.darkBlue};
    color: ${styles.orange};
  `}

  ${props => props.inline && css`
    display: inline;
    line-height: 0;
  `}

  ${props => props.css}
`

class Button extends PureComponent {
  state = {}

  onClick = (e) => {
    if (isFunction(this.props.onClick)) this.props.onClick(e)
  }

  render() {
    const { accent, active, breakpoint, bordered, hover, inline, type, noHover, css } = this.props

    return (
      <ButtonContainer
        accent={accent}
        active={active}
        breakpoint={breakpoint}
        bordered={bordered}
        hover={hover}
        inline={inline}
        noHover={noHover}
        type={type}
        onClick={this.onClick}
        css={css}>
        {this.props.children}
      </ButtonContainer>
    )
  }

  static propTypes = {
    active: PropTypes.bool,
    breakpoint: PropTypes.object,
    children: PropTypes.element,
    hover: PropTypes.bool,
    noHover: PropTypes.bool,
    inline: PropTypes.bool,
    type: PropTypes.string,
    css: PropTypes.string,
    onClick: PropTypes.func
  }

  static defaultProps = {
    active: false,
    breakpoint: {},
    children: undefined,
    hover: false,
    noHover: false,
    inline: false,
    type: 'button',
    css: '',
    onClick: undefined
  }
}

export default Button
