import React, { PureComponent } from 'react'
import update from 'immutability-helper'
import PropTypes from 'prop-types'
import { Player } from 'video-react'
import 'video-react/dist/video-react.css'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import VideoContext from '../VideoContext'
import MuteButton from './MuteButton'
import PlayPauseButton from './PlayPauseButton'
import ReplayButton from './ReplayButton'

const PlayerWrapper = styled.div`
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 1;

  .video-react {
    background-color: transparent;

    .video-react-loading-spinner,
    .video-react-big-play-button,
    .video-react-control-bar {
      display: none !important;
    }

    .video-react-video {
      background-color: transparent;
      box-sizing: border-box;
      height: 56.25vw;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      width: 177.77777778vh;
    }
  }
`

const VideoOverlay = styled.div`
  background-color: ${styles.blue};
  display: block;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: .4;
  width: 100%;
  z-index: 100;
`

class Video extends PureComponent {
  state = {
    paused: false,
    muted: true
  }

  source = React.createRef()

  static contextType = VideoContext

  componentDidMount() {
    this.player.subscribeToStateChange(this.onPlayerStateChange.bind(this))
  }

  onPlayerStateChange(state, prevState) {
    if (state.currentTime !== prevState.currentTime) {
      const { currentTime, setCurrentTime } = this.context

      setCurrentTime(state.currentTime)
    }

    if (state.duration !== prevState.duration) {
      const { duration, setDuration } = this.context

      setDuration(state.duration)
    }

    if (state.paused !== prevState.paused) {
      const { paused } = this.state

      if (paused !== state.paused) this.setState({paused: state.paused})
    }

    if (state.ended !== prevState.ended) {
      const { ended, setEnded } = this.context

      setEnded(state.ended)
    }

    // const buffer = state.buffered && state.buffered.map((buffer, index) => {
    //   console.log(index, state.buffered.start(index), state.buffered.end(index))
    //   console.log('---')
    // })
    //
    // console.log('onPlayerStateChange', buffer)
  }

  componentDidUpdate(prevProps, prevState) {
    const { paused, muted } = this.state
    const { play, setPlay, pause, setPause, seekingTime, setSeekingTime } = this.context

    if (play) {
      setPlay(false)

      this.player.play()
    }

    if (pause) {
      setPause(false)

      this.player.pause()
    }

    if (!isNaN(seekingTime)) {
      setSeekingTime(undefined)

      this.player.seek(seekingTime)
      this.player.play()
    }

    if (paused !== prevState.paused) {
      if (!paused) this.player.play()
      if (paused) this.player.pause()
    }

    if (muted !== prevState.muted) this.player.muted = muted
  }

  componentWillUnmount() {
    this.player.pause()

    if (this.source && this.source.current) {
      this.source.current.removeAttribute('src')
      this.player.load()
    }
  }

  play = () => {
    this.player.play()
  }

  pause = () => {
    this.player.pause()
  }

  load = () => {
    this.player.load()
  }

  render() {
    const { breakpoint, fadeOut, video, width } = this.props
    const { paused, muted } = this.state
    const { currentTime, duration, ended } = this.context

    let { url } = video

    if (!url) return null

    return (
      <React.Fragment>
        <PlayerWrapper>
          <Player ref={(player) => { this.player = player }}
            fluid={false}
            width="100%"
            height="100%"
            autoPlay={true}
            preload="auto"
            muted={muted}
            playsInline={true}
            // poster="live-action_tallinna-maraton.jpg"
          >
            <source ref={this.source} src={url} />
          </Player>

          <VideoOverlay onClick={this.pause} />
        </PlayerWrapper>

        {!(!breakpoint.desktop && ended) && (
          <>
            {!ended && (
              <PlayPauseButton
                breakpoint={breakpoint}
                fadeOut={fadeOut}
                paused={paused}
                onPlay={() => this.setState({paused: false})}
                onPause={() => this.setState({paused: true})}
              />
            ) || (
              <ReplayButton
                fadeOut={fadeOut}
                onPlay={() => this.play()}
              />
            )}

            <MuteButton
              breakpoint={breakpoint}
              fadeOut={fadeOut}
              muted={muted}
              onClick={() => this.setState({muted: !muted})}
            />
          </>
        )}
      </React.Fragment>
    )
  }

  static propTypes = {
    breakpoint: PropTypes.object,
    video: PropTypes.object,
    width: PropTypes.number
  }

  static defaultProps = {
    breakpoint: {},
    video: undefined,
    width: undefined
  }
}

export default Video
