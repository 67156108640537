import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import LayoutContext from 'components/Layout/LayoutContext'
import { Logo as LogoIcon, LogoWording } from 'components/common/vectors'

const Container = styled.div`
  position: ${props => !props.breakpoint.mobile ? `fixed` : `relative`};
  top: ${props => !props.breakpoint.mobile ? `1.35em` : null};
  left: ${props => props.breakpoint.desktop ? `1.35em` : `1em`};
  z-index: 1;

  a {
    display: flex;
    align-items: center;

    .logo {
      height: ${props => !props.breakpoint.mobile ? `2.5em` : `2em`};
    }

    .logo-wording {
      height: 0.75em;
      margin-left: 0.5em;
    }
  }
`

class Logo extends PureComponent {
  state = {}

  static contextType = LayoutContext

  render() {
    const { breakpoint, language } = this.props
    const { isFullLogo } = this.context

    return (
      <Container breakpoint={breakpoint}>
        <NavLink to={language === 'en' ? `/en` : `/`}>
          <LogoIcon className="logo" />

          <LogoWording className="logo-wording" />
        </NavLink>
      </Container>
    )
  }

  static propTypes = {
    breakpoint: PropTypes.object,
    language: PropTypes.string
  }

  static defaultProps = {
    breakpoint: {},
    language: ''
  }
}

export default Logo
