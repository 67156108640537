import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

const Playhead = styled.div.attrs(props => ({
  style: {
    left: props.position,
    opacity: props.hidden ? 0 : 1
  }
}))`
  display: block;
  height: 100%;
  top: 0;
  position: absolute;
  transition:
    left .1s ease,
    opacity .25s ease 250ms;
  width: 1px;

  &::after {
    background-color: ${props => props.markerLine && styles.yellow};
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 1px;
  }
`

const Head = styled.div`
  border: 1px solid ${styles.yellow};
  border-radius: 50%;
  display: block;
  height: 16px;
  top: -7px;
  left: -8px;
  position: absolute;
  width: 16px;

  &::after {
    background-color: ${styles.yellow};
    border-radius: 50%;
    content: "";
    display: block;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50%;
  }
`

const TimelinePlayhead = memo((props) => {
  return (
    <Playhead {...props} onClick={() => props.onClick()}>
      <Head />
    </Playhead>
  )
})

TimelinePlayhead.propTypes = {
  hidden: PropTypes.bool,
  markerLine: PropTypes.bool,
  position: PropTypes.number,
  onClick: PropTypes.func
}

TimelinePlayhead.defaultProps = {
  hidden: false,
  markerLine: false,
  position: 0,
  onClick: function() {}
}

export default TimelinePlayhead
