import StyledComponents, { css as StyledComponentsCSS } from 'styled-components'

export const styled = StyledComponents
export const css = StyledComponentsCSS

export default {
  headingFontFamily: `'Comfortaa', cursive`,
  headingFontWeight: 600,
  // bodyFontFamily: `'Cabin', sans-serif`,
  // bodyFontFamily: `europa, sans-serif`,
  // bodyFontFamily: `'Poppins', sans-serif`,
  bodyFontFamily: `proxima-nova, sans-serif`,
  bodyFontWeight: 400,
  bodyFontWeightLight: 300,
  baseSize: {
    desktop: `1.25vw`,
    tablet: `2vw`,
    mobile: `4.75vw`
  },
  blue: `#023282`,
  darkBlue: `#011C48`,
  darkerBlue: `#011435`,
  orange: `#FCB20E`,
  red: `#ff0000`,
  white: `#ffffff`,
  yellow: `#FDCC09`,
  rgb: {
    blue: `2,50,130`,
    darkBlue: `1,28,72`,
    darkerBlue: `1,20,53`,
    orange: `252,178,14`,
    yellow: `253,204,9`
  }
}
