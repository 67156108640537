import React, { memo } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

const ProgressBarWrapper = styled.div`
  background-color: white;
  border-radius: 1.5px;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

const ProgressBar = styled.div`
  background-color: ${styles.orange};
  display: block;
  height: 100%;
  top: 0;
  position: absolute;
  transition: width .25s ease;
  width: ${props => `${props.position}px`};
`

const TimelineProgressBar = memo((props) => {
  const getPosition = () => {
    const { duration, currentTime, width } = props

    if (isNaN(currentTime) || isNaN(duration) || isNaN(width)) return undefined
    return currentTime * width / duration
  }

  return (
    <ProgressBarWrapper>
      <ProgressBar position={getPosition()} />
    </ProgressBarWrapper>
  )
})

TimelineProgressBar.propTypes = {
  duration: PropTypes.number,
  currentTime: PropTypes.number,
  width: PropTypes.number
}

TimelineProgressBar.defaultProps = {
  duration: undefined,
  currentTime: undefined,
  width: undefined
}

export default TimelineProgressBar
