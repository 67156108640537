import React, { Fragment, PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Reveal from 'react-reveal/Fade'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import LayoutContext from 'components/Layout/LayoutContext'

const ContentContainer = styled.div`
  box-sizing: border-box;
  color: ${styles.white};
  height: ${props => !isNaN(props.height) ? `${props.height}px` : `auto`};
  padding-left: ${props => !props.breakpoint.mobile ? `2em` : `1.5em`};
  padding-right: ${props =>
    props.paddingRight
    ? props.paddingRight
    : !props.breakpoint.mobile
      ? `2em`
      : `1em`
  };
  overflow: hidden;
  width: 100%;

  &::before {
    background-color: ${styles.orange};
    border-radius: 1.5px;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 2px;
  }

  h1 {
    text-transform: uppercase;
  }

  p {
    font-size: .9em;

    ${props => props.breakpoint.tablet && css`
      font-size: 1em;
    `}
  }

  ${props => props.isHidden && css`
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    z-index: -99;
  `}

  ${props => props.animate && !props.isHidden && css`
    transition: height .5s ease ${props.delay}ms;
  `}

  ${props => props.css}
`

class Content extends PureComponent {
  state = {
    seekingSlideIndex: undefined,
    activeSlideIndex: undefined, // currentSlideIndex
    height: 0,
    heightTimeout: undefined,
    paddingRight: undefined,
    timeout: undefined
  }

  preContent = React.createRef()

  setHeight = this.setHeight.bind(this)

  componentDidMount() {
    this.setSeekingSlideIndex()

    window.addEventListener('resize', this.setHeight)
  }

  componentDidUpdate(prevProps, prevState) {
    const { seekingSlideIndex } = this.state

    if (seekingSlideIndex !== prevState.seekingSlideIndex) {
      let { timeout } = this.state

      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => {
        const { stops } = this.props

        this.setState({activeSlideIndex: seekingSlideIndex, paddingRight: stops[seekingSlideIndex] && stops[seekingSlideIndex].verticalPaddingRight})
      }, 1500)

      const height = this.getHeight()

      this.setState({activeSlideIndex: undefined, height, timeout})
    }

    this.setSeekingSlideIndex()

    const { language } = this.props

    if (language !== prevProps.language) this.setHeight()

    const { fadeOut } = this.props

    if (fadeOut && fadeOut !== prevProps.fadeOut) {
      this.setState({activeSlideIndex: undefined})
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight)
  }

  getHeight() {
    let { height } = this.state

    if (this.preContent && this.preContent.current) {
      const clientRect = ReactDOM.findDOMNode(this.preContent.current).getBoundingClientRect()
      height = clientRect.height
    }

    return height
  }

  setHeight() {
    let { heightTimeout } = this.state

    if (heightTimeout) clearTimeout(heightTimeout)

    const height = this.getHeight()

    heightTimeout = setTimeout(() => {
      this.setState({heightTimeout: null})
    }, 1500)

    this.setState({height, heightTimeout})
  }

  setSeekingSlideIndex() {
    const { currentTime, stops } = this.props
    const { seekingSlideIndex } = this.state

    const nextSlideIndex = stops.map((stop, index) => {
      const isCategory = stop.heading ? true : false

      if (isCategory) {
        if (currentTime >= index && !(currentTime >= index+1)) return true

        return false
      } else {
        if (stop.startTime <= currentTime && currentTime <= stop.endTime) return true

        return false
      }
    }).indexOf(true)

    if (seekingSlideIndex !== nextSlideIndex) this.setState({seekingSlideIndex: nextSlideIndex})
  }

  render() {
    const { breakpoint, css, currentTime, stops, fadeOut, hidden } = this.props
    const { seekingSlideIndex, activeSlideIndex, height, heightTimeout, paddingRight } = this.state
    const preSlide = stops[seekingSlideIndex]
    const slide = stops[activeSlideIndex]

    return (
      <LayoutContext.Consumer>
        {context => (
          <>
            <ContentContainer animate={!slide && !heightTimeout} breakpoint={breakpoint} css={css} delay={heightTimeout ? 0 : 1000} height={!hidden ? height : 0} paddingRight={breakpoint.tablet && paddingRight}>
              <Reveal left opposite={fadeOut || breakpoint.mobile ? false : true}
                when={!isNaN(activeSlideIndex)}
              >
                <h1 dangerouslySetInnerHTML={{__html: slide && slide.title && slide.title[context.language]}} />
                <p dangerouslySetInnerHTML={{__html: slide && slide.descriptionHtml && slide.descriptionHtml[context.language]}} />
              </Reveal>
            </ContentContainer>

            <ContentContainer breakpoint={breakpoint} ref={this.preContent} isHidden paddingRight={breakpoint.tablet && preSlide && preSlide.verticalPaddingRight}>
              <h1 dangerouslySetInnerHTML={{__html: preSlide && preSlide.title && preSlide.title[context.language]}} />
              <p dangerouslySetInnerHTML={{__html: preSlide && preSlide.descriptionHtml && preSlide.descriptionHtml[context.language]}} />
            </ContentContainer>
          </>
        )}
      </LayoutContext.Consumer>
    )
  }

  static propTypes = {
    breakpoint: PropTypes.object,
    css: PropTypes.string,
    currentTime: PropTypes.number,
    stops: PropTypes.array,
    opposite: true
  }

  static defaultProps = {
    breakpoint: {},
    css: '',
    currentTime: undefined,
    stops: [],
    opposite: true
  }
}

export default Content
