import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Classnames from 'classnames'
import styled, { css, keyframes } from 'styled-components'
import styles from 'styles/helper'

import VideoContext from '../VideoContext'
import TimelineMarkers from './TimelineMarkers'
import TimelinePlayhead from './TimelinePlayhead'
import TimelineProgressBar from './TimelineProgressBar'
import TimelineSeekArea from './TimelineSeekArea'
import FullLive from '../FullLive'

let height = 4, // em
    spacing = 1, // em
    progressBarHeight = 3 // px

const fromBottom = (props) => keyframes`
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
`

const TimelineWrapper = styled.div`
  animation: 1.5s ${props => fromBottom(props)} ease;
  box-sizing: border-box;
  height: ${height + spacing}em;
  position: fixed;
  left: 0;
  right: 0;
  bottom: ${props => props.fadeOut ? css`-10vw` : 0};
  margin: auto;
  transition: bottom 1s 1000ms;
  width: calc(100vw - 4em - 4em);
  z-index: 1002;
`

const TimelineContainer = styled.div`
  height: calc(100% - ${spacing}em);
  position: absolute;
  margin-top: -${progressBarHeight/2}px;
  transition: top .25s ease 250ms;
  width: 100%;

  ${props => (props.animateEntry || props.hover) ? css`
    top: ${spacing}em;
  ` : css`
    top: ${height/2 + spacing}em;
  `}
`

class Timeline extends PureComponent {
  state = {
    animateEntry: true,
    clientRect: {},
    hidePlayhead: true,
    playbackPosition: 0,
    x: 0,
    y: 0
  }

  setWidth = this.setWidth.bind(this)

  timelineElement = React.createRef()

  static contextType = VideoContext

  componentDidMount() {
    const { items } = this.props

    if (items.length <= 2) this.setState({animateEntry: false})

    setTimeout(() => {
      const { animateEntry } = this.state

      if (animateEntry === true) this.setState({animateEntry: false})
    }, 9500)

    this.setWidth()

    window.addEventListener('resize', this.setWidth)
  }

  componentDidUpdate(prevProps, prevState) {
    const { hidePlayhead } = this.state
    const { setTimelineHidden } = this.context

    if (hidePlayhead !== prevState.hidePlayhead) setTimelineHidden(hidePlayhead)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWidth)
  }

  setWidth() {
    const clientRect = ReactDOM.findDOMNode(this).getBoundingClientRect()

    this.setState({clientRect})
  }

  setMouseCoordinates(e) {
    const { clientRect } = this.state
    const x = e.clientX - clientRect.left

    this.setState({x})
  }

  togglePlayhead(hidePlayhead) {
    const { animateEntry } = this.state

    if (animateEntry) this.setState({animateEntry: false})

    this.setState({hidePlayhead})
  }

  seek(time) {
    const { setSeekingTime } = this.context

    if (isNaN(time)) {
      const { clientRect, x } = this.state
      const { duration } = this.context

      time = (duration * x / clientRect.width)-0.05
    }

    setSeekingTime(time)
  }

  render() {
    const { items, fullUrls, fadeOut } = this.props
    const { animateEntry, clientRect, hidePlayhead, x, y } = this.state
    const { duration, currentTime, setPause, ended } = this.context

    return (
      <>
        <TimelineWrapper ref={this.timelineElement}
          fadeOut={this.props.fadeOut}
          onMouseEnter={(e) => this.togglePlayhead(false)}
          onMouseMove={(e) => this.setMouseCoordinates(e)}
          onMouseLeave={(e) => this.togglePlayhead(true)}
        >
          <TimelineSeekArea onClick={() => this.seek()} />

          <TimelineContainer animateEntry={animateEntry} hover={items.length > 2 && !hidePlayhead}>
            <TimelineProgressBar
              duration={duration}
              currentTime={currentTime}
              width={clientRect.width}
            />

            <TimelinePlayhead
              hidden={hidePlayhead}
              markerLine={items.length > 2}
              position={x}
              // onClick={() => this.seek()} // TimelineSeekArea already captures seeking
            />

            <TimelineMarkers
              currentTime={currentTime}
              duration={duration}
              animateEntry={animateEntry}
              hover={!hidePlayhead}
              stops={items}
              width={clientRect.width}
              onSeek={(time) => this.seek(time)}
            />
          </TimelineContainer>
        </TimelineWrapper>

        <FullLive animateEntry={animateEntry} currentTimeInteger={parseInt(currentTime)} fadeOut={fadeOut} items={fullUrls} onClick={() => setPause(true)} />
      </>
    )
  }

  static propTypes = {
    items: PropTypes.array,
    fullUrls: PropTypes.array,
    fadeOut: PropTypes.bool,
    width: PropTypes.number
  }

  static defaultProps = {
    items: [],
    fullUrls: [],
    fadeOut: false,
    width: undefined
  }
}

export default Timeline
