import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styles, { styled, css } from 'styles'
import axios from 'axios'

import LayoutContext from 'components/Layout/LayoutContext'
import Input from 'components/elements/Input'
import Textarea from 'components/elements/Textarea'
import Button from 'components/elements/Button'

import EmailSent from 'assets/email-sent.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${props => !props.breakpoint.mobile && css`
    flex-basis: 25%;
  `}

  ${props => props.breakpoint.mobile && css`
    flex-basis: 90%;

    h3 {
      text-align: center;
    }
  `}

  form {
    text-align: center;
  }
`

const Submitted = styled.div`
  text-align: center;

  img {
    margin-bottom: .5em;
  }
`

class Form extends PureComponent {
  state = {
    name: '',
    email: '',
    body: ''
  }

  static contextType = LayoutContext

  submit = (e) => {
    e.preventDefault()

    let { name, email, body } = this.state
    const { language } = this.context

    if (!name || !email || !body) {
      if (!name) name = null
      if (!email) email = null
      if (!body) body = null

      return this.setState({name, email, body})
    }

    axios({
      url: 'https://rockstick.ee/emailapi/',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        to: [
          {
            address: 'info@rockstick.ee',
            name: 'Rockstick Production'
          }
        ],
        replyTo: [
          {
            address: email,
            name: name
          }
        ],
        subject: language === 'et' ? 'Uus kiri veebist' : 'New email from web',
        content: {
          text: body,
          html: body
        }
      })
    })
    .then(response => {
      this.props.onSubmit()
    })
    .catch(error => {
      console.log(error)
    })
  }

  render() {
    const { breakpoint, emailSent, view } = this.props
    const { name, email, body } = this.state
    const { getTranslation } = this.context

    return (
      <Container breakpoint={breakpoint}>
        {!emailSent && (
          <>
            <h3>{getTranslation('contact.form.title')}</h3>

            <form onSubmit={this.submit}>
              <Input placeholder={getTranslation('contact.form.name')} value={name} onChange={(name) => this.setState({name})} />
              <Input placeholder={getTranslation('contact.form.email')} type="email" value={email} onChange={(email) => this.setState({email})} />
              <Textarea placeholder={getTranslation('contact.form.body')} value={body} onChange={(body) => this.setState({body})} />
              <Button bordered type="submit">{getTranslation('contact.form.submit')}</Button>
            </form>
          </>
        ) || (
          <Submitted breakpoint={breakpoint}>
            <img src={EmailSent} />

            <h3>{getTranslation('contact.form.submitted_title')}</h3>

            <p>{getTranslation('contact.form.submitted_paragraph')}</p>
          </Submitted>
        )}
      </Container>
    )
  }

  static propTypes = {
    breakpoint: PropTypes.object
  }

  static defaultProps = {
    breakpoint: function() {}
  }
}

export default Form
