import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import styles from 'styles'

import LayoutContext from 'components/Layout/LayoutContext'

import { WatchExternalIcon } from 'components/common/vectors'

const entryAnimation = (props) => keyframes`
  0% {
    right: -100%;
  }
  100% {
    right: 1.5em;
  }
`

const exitAnimation = (props) => keyframes`
  0% {
    right: ${props.position.right};
  }
  100% {
    right: -100%;
  }
`

const FullLiveWrapper = styled.div`
  ${props => props.animateOut && css`
    animation: 3s ${exitAnimation(props)} ease 1000ms;
  `}
  background-color: rgba(${styles.rgb.blue}, .5);
  border: 1px solid ${styles.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3em;
  padding-left: 1em;
  padding-right: 3em;
  position: fixed;
  right: ${props => props.position.right};
  bottom: 6em;
  transition: right .5s ease;
  width: 8em;
  z-index: 1001;

  ${props => props.animateEntry && css`
    animation: 1.5s ${entryAnimation(props)} ease;
    right: 1.5em;
  `}

  &::before {
    content: "";
    display: block;
    height: calc(100% + (.5em + 2px) + (.5em + 2px));
    position: absolute;
    top: calc(-.5em + -2px);
    right: calc(-1.5em + -2px);
    width: calc(100% + (1em + 2px) + (1.5em + 2px));
    z-index: -1;
  }

  &::after {
    background-color: ${styles.white};
    content: "";
    display: block;
    height: 60%;
    position: absolute;
    top: 0;
    left: 1em;
    bottom: 0;
    margin: auto;
    width: 1px;
  }

  &:hover {
    right: 1.5em;
  }

  small {
    color: ${styles.white};
    font-size: .6em;
    letter-spacing: 0.1em;
    line-height: 1.6;
    margin-left: 1.5em;
    text-transform: uppercase;
  }
`

const IconContainer = styled.div`
  background-color: rgba(${styles.rgb.yellow}, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3em;
  position: absolute;
  right: 0;
  width: 3em;

  svg {
    width: 1.5em;
  }
`

class FullLive extends PureComponent {
  state = {
    entryAnimationOver: false
  }

  static contextType = LayoutContext

  componentDidMount() {
    setTimeout(() => {
      this.setState({entryAnimationOver: true})
    }, 2000)
  }

  render() {
    const { animateEntry, currentTimeInteger, fadeOut, items } = this.props
    const { entryAnimationOver } = this.state
    const { getTranslation } = this.context

    let fullUrlWithTime = undefined

    items.map(item => {
      const { startTime, endTime } = item

      const startTimeInteger = parseInt(startTime)
      const endTimeInteger = parseInt(endTime)

      const itemInTime = startTimeInteger <= currentTimeInteger && currentTimeInteger <= endTimeInteger

      if (itemInTime) {
        const { url, urlTime } = item
        const passedTime = currentTimeInteger-startTimeInteger

        fullUrlWithTime = `${url}${urlTime && `?t=${urlTime+passedTime}`}`
      }
    })

    if (!fullUrlWithTime) return null

    return (
      <a href={fullUrlWithTime} target="_blank" onClick={this.props.onClick}>
        <FullLiveWrapper animateEntry={animateEntry || !entryAnimationOver} animateOut={fadeOut} position={{right: css`-10.5em`}}>
          <small>{getTranslation('project.timeline.full_live')}</small>

          <IconContainer>
            <WatchExternalIcon />
          </IconContainer>
        </FullLiveWrapper>
      </a>
    )
  }

  static propTypes = {
    animateEntry: PropTypes.bool,
    currentTimeInteger: PropTypes.number,
    fadeOut: PropTypes.bool,
    items: PropTypes.array,
    onClick: PropTypes.func
  }

  static defaultProps = {
    animateEntry: false,
    currentTimeInteger: undefined,
    fadeOut: false,
    items: [],
    onClick: function() {}
  }
}

export default FullLive
