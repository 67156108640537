export default {
  headingFontFamily: `'Comfortaa', cursive`,
  headingFontWeight: 600,
  // bodyFontFamily: `'Cabin', sans-serif`,
  // bodyFontFamily: `europa, sans-serif`,
  // bodyFontFamily: `'Poppins', sans-serif`,
  bodyFontFamily: `proxima-nova, sans-serif`,
  bodyFontWeight: 400,
  bodyFontWeightLight: 300,
  blue: `#023282`,
  darkBlue: `#011C48`,
  darkerBlue: `#011435`,
  orange: `#FCB20E`,
  white: `#ffffff`,
  yellow: `#FDCC09`,
  rgb: {
    blue: `2,50,130`,
    darkBlue: `1,28,72`,
    darkerBlue: `1,20,53`
  }
}
