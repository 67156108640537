import config from 'config'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import styles from 'styles/helper'

import withWindowDimensions from 'components/withWindowDimensions'

const fillIn = (props) => keyframes`
  0% {
    width: 0%;
    left: 0;
  }
  100% {
    width: 100%;
    left: 0;
  }
`

const fillOut = (props) => keyframes`
  0% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0%;
    left: 100%;
  }
`

const fillInOut = (props) => keyframes`
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0%;
    left: 100%;
  }
`

const AnimationWrapper = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  visibility: ${props => !props.hideChildren ? css`visible` : css`hidden`};

  ${props => (props.animateIn || props.animateOut || props.animateInOut) && css`
    &::after {
      ${props.animateIn && css`
        animation: ${props.duration}ms ${fillIn(props)} forwards;
      ` || props.animateOut && css`
        animation: ${props.duration}ms ${fillOut(props)} ${props.delayAnimateOut}ms forwards;
      ` || props.animateInOut && css`
        animation: ${props.duration*2}ms ${fillInOut(props)} forwards;
      `}
      background-color: ${props => props.overlay ? styles.darkBlue : styles.orange};
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      visibility: visible;
      width: ${props.delayAnimateOut ? 100 : 0}%;
      z-index: 9999;
    }
  `}

  ${props => props.overlay && css`
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100001;
  `}

  ${props => props.css}
`

const AnimationContainer = styled.div`
  height: 100%;
  width: 100%;

  ${props => props.isHidden && css`
    opacity: 0;
  `}
`

class Animation extends PureComponent {
  render() {
    let { animateIn, animateOut, animateInOut, breakpoint, css, delayAnimateOut, duration, enter, exit, overlay, state } = this.props
    let hideChildren = (enter && animateIn) || (exit && animateOut)

    if (animateIn === undefined && animateOut === undefined) {
      if (enter) {
        animateIn = state === 'entering'
        animateOut = state === 'entered'
        hideChildren = animateIn
      } else
      if (exit) {
        animateIn = state === 'exiting'
        animateOut = state === 'exited'
        hideChildren = animateOut
      }
    }

    const enableAnimation = (breakpoint.desktop && config.enableDesktopAnimations) || overlay

    return (
      <AnimationWrapper
        animateIn={enableAnimation && animateIn}
        animateOut={enableAnimation && animateOut}
        animateInOut={enableAnimation && animateInOut}
        css={css}
        delayAnimateOut={delayAnimateOut}
        duration={duration}
        hideChildren={hideChildren}
        overlay={overlay}>
        {this.props.children}
      </AnimationWrapper>
    )
  }

  static propTypes = {
    animateIn: PropTypes.bool,
    animateOut: PropTypes.bool,
    animateInOut: PropTypes.bool,
    breakpoint: PropTypes.object,
    css: PropTypes.string,
    delayAnimateOut: PropTypes.number,
    duration: PropTypes.number,
    enter: PropTypes.bool,
    exit: PropTypes.bool,
    overlay: PropTypes.bool,
    state: PropTypes.string
  }

  static defaultProps = {
    animateIn: undefined, // Use Transition's state as a trigger
    animateOut: undefined, // Use Transition's state as a trigger
    animateInOut: undefined,
    breakpoint: {},
    css: '',
    delayAnimateOut: 0,
    duration: 750,
    enter: undefined, // Do not use Transition's state as a trigger
    exit: undefined, // Do not use Transition's state as a trigger
    overlay: false,
    state: ''
  }
}

export default withWindowDimensions(Animation)
