import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import styles from 'styles/helper'

const fillInOut = (props) => keyframes`
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0%;
    left: 100%;
  }
`

const AnimationWrapper = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;

  ${props => props.animating && css`
    &::after {
      animation: ${props.duration}ms ${fillInOut(props)} ease;
      background-color: ${props.overlay ? styles.darkBlue : styles.orange};
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 0;
      z-index: 9999;
    }
  `}

  ${props => props.overlay && css`
    position: absolute;
    top: 0;
    left: 0;
  `}

  ${props => props.css}
`

const AnimationContainer = styled.div`
  height: 100%;
  width: 100%;

  ${props => props.isHidden && css`
    opacity: 0;
  `}
`

class Transition extends PureComponent {
  state = {
    animating: false,
    timeout: undefined
  }

  componentDidUpdate(prevProps, prevState) {
    const { delay, duration, animate, hideChildrenDelay } = this.props

    setTimeout(() => {
      if (animate && animate !== prevProps.animate) {
        let { animating, timeout } = this.state

        if (timeout) clearTimeout(timeout)

        if (animate) {
          animating = true

          if (hideChildrenDelay) {
            timeout = setTimeout(() => {
              this.setState({hideChildren: true})
            }, hideChildrenDelay)
          }

          timeout = setTimeout(() => {
            this.setState({animating: false})
          }, duration)
        } else {
          animating = false
        }

        this.setState({animating, timeout})
      }
    }, delay)
  }

  render() {
    const { css, duration, hideChildren, overlay } = this.props
    const { animating } = this.state

    return (
      <AnimationWrapper css={css} duration={duration} animating={animating} overlay={overlay}>
        <AnimationContainer duration={duration} animating={animating} isHidden={hideChildren || this.state.hideChildren}>
          {this.props.children}
        </AnimationContainer>
      </AnimationWrapper>
    )
  }

  static propTypes = {
    css: PropTypes.string,
    delay: PropTypes.bool,
    duration: PropTypes.number,
    animate: PropTypes.bool,
    hideChildren: PropTypes.bool,
    hideChildrenDelay: PropTypes.number,
    overlay: PropTypes.bool
  }

  static defaultProps = {
    css: '',
    delay: 0,
    duration: 1500,
    animate: false,
    hideChildren: false,
    hideChildrenDelay: undefined,
    overlay: false
  }
}

export default Transition
