import React, { createContext, useContext, useState, useEffect } from 'react'

export const WindowDimensionsContext = createContext(null)

export const WindowDimensionsContextProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  }, [])

  return (
    <WindowDimensionsContext.Provider value={{
      ...dimensions, // Dimensions are based on *-device-width
      breakpoint: {
        // mobile: dimensions.width <= 767,
        mobile: (
          window.matchMedia("screen and (orientation: portrait) and (max-width: 767.98px)").matches
        ),
        // tablet: dimensions.width > 768 && dimensions.width <= 1179,
        tablet: (
          window.matchMedia("screen and (orientation: landscape) and (max-width: 1179.98px)").matches ||
          window.matchMedia("screen and (min-width: 768px) and (max-width: 1179.98px)").matches
        ),
        // desktop: dimensions.width > 1180
        desktop: (
          window.matchMedia("(min-width: 1180px)").matches
        ),
        orientation: {
          portrait: (
            window.matchMedia("screen and (orientation: portrait)").matches
          ),
          landscape: (
            window.matchMedia("screen and (orientation: landscape)").matches
          )
        }
      }
    }}>
      {children}
    </WindowDimensionsContext.Provider>
  )
}

export const useWindowDimensionsContext = () => {
  return useContext(WindowDimensionsContext)
}

export default WindowDimensionsContext
