import React, { memo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import { useLayoutContext } from 'components/Layout/LayoutContext'
import Button from 'components/elements/Button'

const Container = styled.div`
  position: fixed;
  right: ${props => props.breakpoint.desktop ? `1em` : `.75em`};
  bottom: ${props => props.breakpoint.desktop ? `1em` : `.75em`};
  z-index: 1001;
`

const LanguageSwitcher = memo((props) => {
  const { breakpoint, view } = props
  const { language, languageIsChanging, setLanguage } = useLayoutContext()

  const [selectedLanguage, setSelectedLanguage] = useState(language)

  const clickHandler = (selectedLanguage) => {
    if (!languageIsChanging) {
      if (language !== selectedLanguage) {
        setSelectedLanguage(selectedLanguage)

        setLanguage(selectedLanguage, 750)
      }
    }
  }

  return (
    <Container breakpoint={breakpoint}>
      <NavLink to="/">
        <Button onClick={() => clickHandler('et')} active={selectedLanguage === 'et'} css={css`border: 1px solid ${selectedLanguage === 'et' ? styles.darkBlue : css`white`};`}>
          ET
        </Button>
      </NavLink>

      <NavLink to="/en">
        <Button onClick={() => clickHandler('en')} active={selectedLanguage === 'en'} css={css`border: 1px solid ${selectedLanguage === 'en' ? styles.darkBlue : css`white`};`}>
          EN
        </Button>
      </NavLink>
    </Container>
  )
})

LanguageSwitcher.propTypes = {
  breakpoint: PropTypes.object
}

LanguageSwitcher.defaultProps = {
  breakpoint: {}
}

export default LanguageSwitcher
