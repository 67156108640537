import React, { memo, useState, useEffect } from 'react'
import { Transition } from 'react-transition-group'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

const StyledImage = styled.img`
  height: 100%;
  object-fit: cover;
  opacity: ${props => props.visible ? 1 : 0};
  position: absolute;
  transition: opacity .25s ease;
  width: 100%;
`

const Image = memo((props) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    props.onLoaded()
  }, loaded)

  return (
    <StyledImage src={props.src} onLoad={() => setLoaded(true)} visible={loaded} />
  )
})

Image.propTypes = {
  src: PropTypes.string,
  onLoaded: PropTypes.func
}

Image.defaultProps = {
  src: '',
  onLoaded: function() {}
}

export default Image
