import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import VideoContext from './VideoContext'

const Container = styled.div`
  display: flex;
  height: 2px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: calc(2em - 1.5px);
  margin: auto;
  width: calc(100% - ${props => props.padding} - ${props => props.padding});
  z-index: 1001;
`

const Tab = styled.div`
  background-color: ${styles.white};
  flex: 1;
  height: 100%;
  position: relative;
  top: 0;

  ${props => props.animate && css`
    &::before {
      /* transition: width .25s ease; */
    }
  `}

  &::before {
    background-color: ${styles.orange};
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => props.position};
  }

  &::after {
    content: "";
    display: block;
    height: 2.25em;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  &:not(:last-child) {
    margin-right: .5em;
  }
`

const getPosition = (props) => {
  const { startTime, endTime, currentTime } = props

  const markerLength = endTime-startTime
  const markerTime = currentTime-startTime

  return markerTime*100/markerLength
}

class MobileProgressIndicator extends PureComponent {
  state = {}

  static contextType = VideoContext

  render() {
    const { breakpoint, items } = this.props
    const { currentTime, setSeekingTime } = this.context

    return (
      <Container breakpoint={breakpoint} padding={`4em`}>
        {items && items.map((item, index) => {
          const { startTime, endTime } = item

          const current = startTime <= currentTime && currentTime <= endTime
          const upcoming = startTime > currentTime

          return (
            <Tab
              animate={current}
              position={current ? `${getPosition({...item, currentTime})}%` : upcoming ? `0%` : `100%`}
              onClick={() => setSeekingTime(startTime)} />
          )
        })}
      </Container>
    )
  }

  static propTypes = {
    breakpoint: PropTypes.object,
    items: PropTypes.array
  }

  static defaultProps = {
    breakpoint: {},
    items: undefined
  }
}

export default MobileProgressIndicator
