import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Classnames from 'classnames'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

const ImageElement = styled.div`
  background-image: url(${props => props.backgroundImage});
  background-position: ${props =>
    props.position === 'top'
    ? css`center top`
    : props.position === 'bottom'
      ? css`center bottom`
      : props.position === 'left'
        ? css`left center`
          : props.position === 'right'
            ? css`right center`
            : css`center`
  };
  background-repeat: no-repeat;
  background-size: ${props =>
    props.position === 'top' || props.position === 'bottom'
    ? css`100% auto`
    : props.position === 'left' || props.position === 'right'
      ? css`auto 100%`
      : css`contain`
  };
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: opacity .5s ease;
  opacity: ${props => props.visible ? 1 : 0};
  width: 100%;
  z-index: ${props => props.visible ? 1 : -1};

  img {
    width: 100%;
  }
`

class GraphicsImageElement extends PureComponent {
  render() {
    const { itemInTime, image, position } = this.props

    return (
      <ImageElement
        backgroundImage={image}
        position={position}
        visible={itemInTime} />
    )
  }

  static propTypes = {
    itemInTime: PropTypes.bool,
    image: PropTypes.string,
    position: PropTypes.string
  }

  static defaultProps = {
    itemInTime: false,
    image: '',
    position: ''
  }
}

export default GraphicsImageElement
