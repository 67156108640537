import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import PropTypes from 'prop-types'
import Reveal from 'react-reveal/Fade'
import styled, { css, keyframes } from 'styled-components'
import styles from 'styles/helper'

import LayoutContext from 'components/Layout/LayoutContext'
import TransitionAnimation from 'components/TransitionAnimation'

import { FullLogo } from 'components/common/vectors'
import Logo from 'components/Layout/Logo'
import Arrow from 'components/elements/Arrow'
import Button from 'components/elements/Button'
import Tab from 'components/elements/Tab'
import NavigationModal from 'components/Layout/NavigationModal'

const toTop = (props) => keyframes`
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
`

const fromTop = (props) => keyframes`
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
`

const HeaderContainer = styled.div`
  // ${props => !props.animateOut ? css`
  //   animation: 1.5s ${fromTop(props)} ease;
  // ` : css`
  //   animation: 3s ${toTop(props)} ease;
  // `}
  align-items: center;
  display: flex;
  flex-flow: wrap;
  height: auto;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  touch-action: manipulation;
  width: auto;
  z-index: 2002;

  ${props => props.breakpoint.desktop && css`
    padding: 1em;
  `}

  ${props => props.breakpoint.tablet && css`
    padding: .75em;
  `}

  ${props => props.breakpoint.mobile && css`
    margin-top: ${props.hideOnScroll ? '-4em' : 0};
    transition: margin-top .5s ease;

    &::before {
      background-color: ${!props.transparent ? styles.darkBlue : css`transparent`};
      content: "";
      display: block;
      height: 4em;
      position: absolute;
      top: 0;
      left: 0;
      transition: background-color .25s ease 750ms;
      width: 100%;
      z-index: 1;
    }
  `}
`

const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  line-height: 0;
  position: relative;
  white-space: nowrap;

  ${props => props.breakpoint.tablet && css`
    flex-basis: 25%;
  `}
`

const HeaderTabs = styled.div`
  ${props => props.breakpoint.desktop && css`
    margin-right: 10%;
    width: 35vw;
  `}

  ${props => props.breakpoint.tablet && css`
    flex-basis: 57.5%;
  `}

  ${props => props.breakpoint.mobile && css`
    background-image: linear-gradient(to bottom, rgba(${styles.rgb.blue}, .4), transparent);
    box-sizing: border-box;
    display: flex;
    flex: 0 0 100%;
    font-size: .95em;
    justify-content: space-between;
    padding: 1em 1.5em;
    order: 3;

    button {
      padding: 1vw 0;
    }
  `}
`

const HeaderNavigation = styled.div`
  text-align: right;

  ${props => props.breakpoint.tablet && css`
    flex-basis: 17.5%;
  `}

  ${props => props.breakpoint.mobile && css`
    height: 2.5em;
    line-height: 0;
    position: relative;
    margin: .75em;
    min-width: 2.5em;
    z-index: 10002;

    a {
      > div {
        height: 100%;
        width: 100%;
      }
    }
  `}
`

const MenuIconWrapper = styled.div`
  height: 1em;
  position: relative;
  width: 1em;
`

const MenuIcon = styled.div`
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: all .15s ease;
  width: 100%;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto
    transition: all .15s ease;
    width: 100%;
  }

  ${props => !props.open ? css`
    background-color: ${styles.darkBlue};

    &::before {
      background-color: ${styles.darkBlue};
      top: -.65em;
    }

    &::after {
      background-color: ${styles.darkBlue};
      bottom: -.65em;
    }
  ` : css`
    &::before {
      background-color: ${styles.orange};
      transform: rotate(45deg);
    }

    &::after {
      background-color: ${styles.orange};
      transform: rotate(-45deg);
    }
  `}
`

const ArrowWrapper = styled.div`
  display: inline-block;
  position: relative;
  padding-right: 15px;

  &::after {
    content: "";
    cursor: pointer;
    display: block;
    height: 300%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
  }
`

let scrollTop = 0
let hideOnScroll = false

class Header extends PureComponent {
  state = {
    hideOnScroll,
    showNavigationModal: false
  }

  onScroll = this.onScroll.bind(this)

  static contextType = LayoutContext

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll)
  }

  onScroll(e) {
    const { currentView } = this.context

    if (!currentView.project) {
      let prev = {scrollTop, hideOnScroll}
      scrollTop = (e === undefined || e.target == document) ? window.pageYOffset : e.target.scrollTop

      if (scrollTop > prev.scrollTop) {
        if (scrollTop > 70) hideOnScroll = true
      } else {
        hideOnScroll = false
      }

      if (hideOnScroll !== prev.hideOnScroll) this.setState({hideOnScroll})
    }
  }

  renderTabs() {
    const { breakpoint } = this.props
    const { categories, language, languageIsChanging, categoryIndex, setCategoryIndex, view } = this.context

    const clickHandler = (index) => {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })

      setCategoryIndex(index)
    }

    return (
      <HeaderTabs breakpoint={breakpoint}>
        {
          categories.map((category, index) => (
            <Tab active={categoryIndex === index} onClick={() => clickHandler(index)}>
              {breakpoint.mobile ? category.heading[language].replace('Spordiv', 'V') : category.heading[language]}
            </Tab>
          ))
        }
      </HeaderTabs>
    )
  }

  render() {
    const { breakpoint } = this.props
    const { hideOnScroll, showNavigationModal } = this.state
    const { currentView, language, languageIsChanging, setLanguage, getTranslation, view } = this.context

    if (!(currentView.home || currentView.contact || breakpoint.mobile) || currentView.project) return null

    return (
      <HeaderContainer animateOut={view !== 'entered'} breakpoint={breakpoint} transparent={currentView.project} hideOnScroll={hideOnScroll}>
        <HeaderLogo breakpoint={breakpoint}>
          <Logo breakpoint={breakpoint} language={language} />
        </HeaderLogo>

        {currentView.home && this.renderTabs()}

        <HeaderNavigation breakpoint={breakpoint}>
          <>
            {!breakpoint.mobile && currentView.home && (
              <NavLink to={language === 'en' ? `/en/contact` : `/kontakt`}>
                <Button breakpoint={breakpoint} bordered>{getTranslation('contact')}</Button>
              </NavLink>
            )}

            {breakpoint.mobile && (
              <Button breakpoint={breakpoint} accent={!showNavigationModal} inline css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                border: 1px solid ${styles.orange};
                width: 2.5em;
                z-index: 10002;
              `} onClick={() => this.setState({showNavigationModal: !showNavigationModal})}>
                <MenuIconWrapper>
                  <MenuIcon breakpoint={breakpoint} open={showNavigationModal} onClick={() => this.setState({showNavigationModal: !showNavigationModal})} />
                </MenuIconWrapper>
              </Button>
            )}
          </>
        </HeaderNavigation>

        {breakpoint.mobile && (
          <Transition
            in={showNavigationModal}
            timeout={{
              exit: 250
            }}
            appear
            mountOnEnter
            unmountOnExit>
            {state => {
              return (
                // state change: exited -> entering -> entered -> exiting -> exited
                <NavigationModal
                  breakpoint={breakpoint}
                  hidden={state !== 'entered'}
                  onHide={() => this.setState({showNavigationModal: false})} />
              )
            }}
          </Transition>
        )}
      </HeaderContainer>
    )
  }

  static propTypes = {
    breakpoint: PropTypes.object
  }

  static defaultProps = {
    breakpoint: {}
  }
}

export default Header
