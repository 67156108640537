import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

const TabContainer = styled.button`
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid ${props => props.active ? styles.orange : 'transparent'};
  color: ${styles.white};
  cursor: pointer;
  font-size: 1em;
  outline: none;
  padding: 1em;
  text-transform: uppercase;
  width: auto;

  ${props => props.css}
`

const Tab = memo((props) => {
  return (
    <TabContainer active={props.active} css={props.css} onClick={props.onClick}>
      {props.children}
    </TabContainer>
  )
})

Tab.propTypes = {
  children: PropTypes.element,
  css: PropTypes.string,
  onClick: PropTypes.func
}

Tab.defaultProps = {
  children: undefined,
  css: '',
  onClick: function() {}
}

export default Tab
