import React, { memo, PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import { useSwipeable } from 'react-swipeable'
import styled, { css, keyframes } from 'styled-components'
import styles from 'styles/helper'

import Header from 'components/Project/Header'
import { VideoNextBroadcast, VideoReplayIcon, WatchFullLiveIcon } from 'components/common/vectors'
import Button from 'components/elements/Button'
import VideoContext, { useVideoContext } from 'components/Project/VideoContext'
import LayoutContext from 'components/Layout/LayoutContext'
import Animation from 'components/Animation'
import ProjectContent from 'components/Project/ProjectContent'
import MobileProgressIndicator from './MobileProgressIndicator'
import Timeline from './Timeline/Timeline'
import Video from './Video/Video'
import Loader from './Loader'
import Graphics from './Graphics'

import Rotate from 'assets/rotate.png'

const VideoWrapper = styled.div`
  height: 100vh;
  position: relative;
  opacity: ${props => props.visible ? 1 : 0};
  width: 100%;

  ${props => props.breakpoint.mobile && css`
    max-height: -webkit-fill-available;
  `}
`

const init = (props) => keyframes`
  0%, 99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Container = styled.div`
  animation: 750ms ${props => init(props)} ease; // Enables to autoplay the video before showing the component
  height: 100%;
  width: 100%;
  opacity: ${props => props.visible ? 1 : 0};
`

const RotateContainer = styled.div`
  height: 1.5em;
  position: fixed;
  right: 1em;
  bottom: 4em;
  width: 2em;
  z-index: 2001;

  img {
    height: 100%;
  }
`

const EndScreen = styled.div`
  background-color: rgba(${styles.rgb.darkerBlue},.4);
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1em;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 1s ease;
  z-index: ${props => props.visible ? 1001 : 1};

  ${props => !props.breakpoint.desktop && css`
    /* font-size: .9em; */
  `}

  a {
    color: white;
    text-decoration: none;
  }

  h1 {
    text-align: center;
    text-transform: uppercase;
  }

  p {
    margin: 2em 0;
  }

  .cta {
    margin-bottom: 4em;
    text-align: center;
  }

  .actions {
    display: flex;
    position: absolute;
    bottom: ${props => props.breakpoint.desktop ? css`4em` : css`2em`};

    button {
      display: inline-flex;
      align-items: center;

      svg {
        height: 2em;
        margin: 0 1em;
      }
    }
  }
`

const withSwipeable = WrappedComponent => {
  const Swipeable = memo(props => {
    const { currentTime, setSeekingTime } = useVideoContext()
    const { features } = props.project.video

    const seek = (forward = true) => {
      let seekingTime = undefined

      features.map((feature, index) => {
        const { startTime, endTime } = feature

        const itemInTime = startTime < currentTime && currentTime < endTime

        if (itemInTime) {
          const nextFeature = features[index+(forward ? 1 : -1)]

          seekingTime = nextFeature
            ? nextFeature.startTime
            : forward
              ? endTime
              : startTime
        }
      })

      if (!isNaN(seekingTime)) setSeekingTime(seekingTime)
    }

    const eventHandlers = useSwipeable({
      onSwipedLeft: (eventData) => seek(true),
      onSwipedRight: (eventData) => seek(false)
    })

    return (
      <WrappedComponent {...props} swipeEventHandlers={eventHandlers} />
    )
  })

  return Swipeable
}

class VideoContainer extends PureComponent {
  state = {}

  static contextType = VideoContext

  renderTimeline() {
    const { breakpoint, project, view, width } = this.props
    const { duration, ended } = this.context

    const hasLoaded = !isNaN(duration) && !isNaN(duration) !== 0

    if (!hasLoaded || !project.video.features || (!breakpoint.desktop && ended)) return null

    return breakpoint.desktop
      ? <Timeline
          items={project.video.features}
          fullUrls={project.video.fullUrls}
          fadeOut={view === 'exiting'}
          width={width} />
      : <MobileProgressIndicator
          breakpoint={breakpoint}
          items={project.video.features} />
  }

  renderEndScreen() {
    const { breakpoint, project } = this.props
    const { setSeekingTime, ended } = this.context

    return (
      <LayoutContext.Consumer>
        {context => {
          const { categoryIndex, language, getTranslation } = context

          const category = categoryIndex === 2 ? 'highlights' : categoryIndex === 1 ? 'live_events' : 'live_action'

          return (
            <EndScreen breakpoint={breakpoint} visible={ended}>
              <h1>{getTranslation(`project.${category}.ended_title`)}</h1>

              <div className="cta">
                <p dangerouslySetInnerHTML={{__html: getTranslation(`project.${category}.ended_paragraph`)}} />

                <NavLink to={language === 'en' ? `/en/contact` : `/kontakt`}>
                  <Button bordered>{getTranslation(`project.${category}.contact`)}</Button>
                </NavLink>
              </div>

              <div className="actions">
                {breakpoint.desktop && project.video.fullUrl && (
                  <a href={project.video.fullUrl} target="_blank">
                    <Button css={css`font-size: .9em;`}>
                      <WatchFullLiveIcon />

                      {getTranslation(`project.${category}.full_link`)}
                    </Button>
                  </a>
                )}

                {!breakpoint.desktop && (
                  <Button css={css`font-size: .9em;`} onClick={() => setSeekingTime(0)}>
                    <VideoReplayIcon stroke={.25} />
                  </Button>
                )}

                <NavLink to={project.nextUrl}>
                  <Button css={css`font-size: .9em;`}>
                    {breakpoint.desktop && getTranslation(`project.${category}.next_link`)}

                    <VideoNextBroadcast />
                  </Button>
                </NavLink>
              </div>
            </EndScreen>
          )
        }}
      </LayoutContext.Consumer>
    )
  }

  render() {
    const { breakpoint, project, width, visible, view } = this.props
    const { currentTime, duration, ended } = this.context

    return (
      <LayoutContext.Consumer>
        {context => {
          const { language, getTranslation } = context

          // const hasLoaded = !isNaN(duration) && !isNaN(duration) !== 0 && currentTime > 0 // TODO: Using `currentTime > 0` sets it false if beginning of the video is clicked
          const hasLoaded = !isNaN(duration) && !isNaN(duration) !== 0

          return (
            <VideoWrapper breakpoint={breakpoint} visible={visible}>
              {visible && <Header breakpoint={breakpoint} fadeOut={view === 'exiting'} ended={ended} />}

              <Container visible={view === 'entered' || view === 'exiting'} {...this.props.swipeEventHandlers}>
                <Video
                  breakpoint={breakpoint}
                  fadeOut={view === 'exiting'}
                  video={project.video}
                  width={width} />

                <ProjectContent
                  breakpoint={breakpoint}
                  stops={project.video.features}
                  hide={!visible}
                  fadeOut={view === 'exiting'} />

                {visible && (
                  <div className="outer-timeline">
                    {!breakpoint.mobile && <Graphics breakpoint={breakpoint} items={project.video.graphics} />}

                    {this.renderTimeline()}

                    {breakpoint.mobile && !ended && (
                      <RotateContainer>
                        <img src={Rotate} />
                      </RotateContainer>
                    )}

                    {this.renderEndScreen()}
                  </div>
                )}
              </Container>
            </VideoWrapper>
          )
        }}
      </LayoutContext.Consumer>
    )
  }

  static propTypes = {
    breakpoint: PropTypes.object,
    project: PropTypes.object,
    width: PropTypes.number
  }

  static defaultProps = {
    breakpoint: {},
    project: {},
    width: undefined
  }
}

export default withSwipeable(VideoContainer)
