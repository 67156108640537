import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import Arrow from 'components/elements/Arrow'
import LayoutContext from 'components/Layout/LayoutContext'
import BackgroundImage from 'components/Layout/BackgroundImage'
import HomeAnimation from './HomeAnimation'
import Animation from 'components/Animation'
import Button from 'components/elements/Button'
import HomeContent from 'components/Home/HomeContent'
import LanguageSwitcher from 'components/Home/LanguageSwitcher'

const Project = styled.div`
  ${props => props.addMarginBottom && css`
    margin-bottom: ${props => props.breakpoint.tablet ? css`1.2vw` : css`2.4vw`};
  `}

  ${props => props.breakpoint.mobile && css`
    background-color: rgba(${styles.rgb.blue}, .5);
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: ${props.offsetLeft ? `flex-start` : `flex-end`};
    height: 50vw;
    padding: 2vw;
    position: relative;
    margin-left: ${props.offsetLeft ? null : `10vw`};
    margin-right: ${props.offsetLeft ? `10vw` : null};
    margin-bottom: 10vw;
    width: auto;
    z-index: 1001;

    &::after {
      background-color: ${styles.blue};
      opacity: .35;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
  `}

  button {
    padding-left: ${props => !props.breakpoint.mobile ? `3em` : `2em`};
    padding-right: ${props => !props.breakpoint.mobile ? `3em` : `2em`};
    text-align: left;
    width: ${props => props.width*(props.widthPercentage/100)}px;
    white-space: pre-wrap;
  }
`

const isOdd = (number) => {
  return (number % 2) === 1
}

class Home extends PureComponent {
  state = {
    width: undefined
  }

  setWidth = this.setWidth.bind(this)

  project = React.createRef()

  static contextType = LayoutContext

  componentDidMount() {
    this.setWidth()

    window.addEventListener('resize', this.setWidth)
  }

  componentDidUpdate(prevProps, prevState) {
    const { width } = this.state

    if (!width) this.setWidth()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWidth)
  }

  setWidth() {
    const prevState = this.state
    let { width } = prevState

    if (this.project && this.project.current) {
      const clientRect = ReactDOM.findDOMNode(this.project.current).getBoundingClientRect()
      width = clientRect.width
    }

    if (prevState.width !== width) this.setState({width})
  }

  render() {
    const { activeCategory, activeProjectIndex, breakpoint, hideElements, project, index } = this.props
    const { width } = this.state
    const { language, languageIsChanging, view } = this.context

    const isFirst = index === 0
    const isLast = index === activeCategory.projects.length-1

    let { backgroundImage } = project
    const widthPercentage = breakpoint.mobile ? 80 : 100

    return (
      <Project ref={this.project} addMarginBottom={!isLast} breakpoint={breakpoint} offsetLeft={isOdd(index)} width={width} widthPercentage={widthPercentage}>
        {breakpoint.mobile && <BackgroundImage src={backgroundImage} breakpoint={breakpoint} />}

        <HomeAnimation changing={hideElements} widthPercentage={widthPercentage}>
          <NavLink
            to={project.slug && {
              pathname: language === 'en'
                ? `/en/${project.slug['en']}`
                : `/${project.slug['et']}`,
              viaLink: true
            } || {pathname: '/'}}
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}>
            <Button
              hover={activeProjectIndex === index}
              noHover={true}
              interactive={false}
              css={css`display: flex; align-items: center; justify-content: space-between;`}>
              {project.title[language]}
            </Button>

            {(!breakpoint.desktop || (breakpoint.desktop && activeProjectIndex === index)) && (
              <Arrow color={styles.orange} height={14} css={css`cursor: pointer; margin: 0 30px;`} />
            )}
          </NavLink>
        </HomeAnimation>
      </Project>
    )
  }

  static propTypes = {
    activeCategory: PropTypes.object,
    activeProjectIndex: PropTypes.number,
    breakpoint: PropTypes.object,
    hideElements: PropTypes.bool,
    project: PropTypes.object,
    index: PropTypes.number,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
  }

  static defaultProps = {
    activeCategory: {},
    activeProjectIndex: undefined,
    breakpoint: {},
    hideElements: false,
    project: {},
    index: undefined,
    onMouseEnter: function() {},
    onMouseLeave: function() {}
  }
}

export default Home
