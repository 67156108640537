import React, { memo, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'
import classNames from 'classnames'

import { useLayoutContext } from 'components/Layout/LayoutContext'

import Arrow from 'components/elements/Arrow'
import Button from 'components/elements/Button'

const ModalBackdrop = styled.div`
  background-color: rgba(${styles.rgb.darkBlue}, .25);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: ${props => props.visible ? 1 : 0};
  transition:
    opacity .25s ease;
  width: 100%;
  z-index: 10000;
`

const Modal = styled.div`
  background-color: ${styles.darkBlue};
  background-image: linear-gradient(to right, ${styles.darkBlue}, ${styles.darkerBlue});
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-wrap: nowrap;
  letter-spacing: 1px;
  position: fixed;
  opacity: ${props => props.visible ? 1 : 0};
  transition:
    top .25s ease,
    opacity .25s ease;
  text-transform: uppercase;
  z-index: 10001;

  ${props => props.breakpoint.desktop && css`
    top: ${props => props.visible ? css`5em` : css`4em`};
    left: 1em;
    width: 42.5em;
  `}

  ${props => props.breakpoint.tablet && css`
    padding-left: 10em;
    padding-right: 10em;
  `}

  ${props => !props.breakpoint.desktop && css`
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding-top: 2em;
    padding-bottom: 4em;
    top: 0;
    width: 100%;
  `}

  ${props => props.css}
`

const Project = styled.div`
  font-size: .8em;
  width: 100%;

  ${props => props.breakpoint.desktop && css`
    font-size: .65em;
    padding: 1em 0;
  `}

  hgroup {
    padding: 5px 5px 5px 2em;

    ${props => !props.breakpoint.desktop && css`
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: .5em 2em;
      text-align: center;
    `}

    h4 {
      font-size: 1.3em;
      font-weight: normal;
      letter-spacing: 0.1em;

      &.title {
        ${props => !props.breakpoint.desktop && css`
          font-size: 1em;
          opacity: .8;
        `}
      }
    }
  }

  ${props => props.language && css`
    hgroup {
      justify-content: flex-start;
      padding-top: 0;
      padding-bottom: 0;

      h4 {
        margin-top: 0
        margin-bottom: 2em;
      }
    }
  `}

  ul {
    list-style: none;
    padding: .5em 2em;
    margin: 0;

    li {
      line-height: 1.4;
      position: relative;
      margin-bottom: 1.4em;

      a {
        color: white;
        display: block;
        padding-right: 1em;
        text-decoration: none;

        ${props => !props.breakpoint.desktop && css`
          font-size: 1.3em;
        `}

        ${props => props.breakpoint.desktop && css`
          &:hover {
            color: ${styles.orange};
          }

          &:not(:hover) {
            .arrow {
              display: none;
            }
          }
        `}

        &.active {
          color: ${styles.orange};

          .arrow {
            display: none;
          }
        }

        .arrow {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
`

const Footer = styled.div`
  align-items: center;
  background-color: ${styles.darkBlue};
  display: flex;
  height: 4em;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;

  ul {
    display: flex;
    justify-content: space-evenly;
    padding: 0;
    margin: 0
    width: 100%;

    li {
      list-style: none;

      a,
      span {
        color: white;
        cursor: pointer;
        text-decoration: none;
      }

      span {
        &.active {
          border-bottom: 1px solid ${styles.orange};
        }
      }
    }
  }
`

const NavigationModal = memo((props) => { // TODO: Could be renamed to ProjectLauncher
  const { breakpoint, hidden } = props
  const { currentView, categories, language, setLanguage, setFixedLayout, getTranslation } = useLayoutContext()

  if (!currentView.project) {
    useEffect(() => {
      setFixedLayout(true)

      return () => {
        setFixedLayout(false)
      }
    }, [])
  }

  return (
    <>
      <ModalBackdrop visible={!hidden} onClick={() => props.onHide()} />

      <Modal breakpoint={breakpoint} visible={!hidden}>
        {categories.map((category, index) => {
          const { heading, projects } = category

          return (
            <Project breakpoint={breakpoint} key={index}>
              <hgroup>
                <h4 className="title">{heading[language]}</h4>
              </hgroup>

              <ul>
                {projects.map((project, index) => {
                  const { slug, title } = project

                  return (
                    <li key={index}>
                      {slug && (
                        <NavLink to={{
                          pathname: language === 'en' ? `/en/${slug[language]}` : `/${slug[language]}`,
                          viaLink: true
                        }} onClick={props.onHide}>
                          {title[language]}

                          {breakpoint.desktop && <Arrow color={styles.orange} height={10} css={css`margin-left: 15px;`} />}
                        </NavLink>
                      ) || title[language]}
                    </li>
                  )
                })}
              </ul>
            </Project>
          )
        })}

        {!breakpoint.desktop && (
          <Footer language breakpoint={breakpoint}>
            <ul>
              <li>
                <NavLink to={language === 'en' ? `/en/contact` : `/kontakt`} onClick={props.onHide}>
                  {getTranslation('contact')}
                </NavLink>
              </li>

              <li>
                <span className={classNames({'active': language === 'et'})} onClick={() => setLanguage('et')}>ET</span>
              </li>

              <li>
                <span className={classNames({'active': language === 'en'})} onClick={() => setLanguage('en')}>EN</span>
              </li>
            </ul>
          </Footer>
        )}
      </Modal>
    </>
  )
})

NavigationModal.propTypes = {
  breakpoint: PropTypes.object,
  hidden: PropTypes.bool,
  onHide: PropTypes.func
}

NavigationModal.defaultProps = {
  breakpoint: {},
  hidden: true,
  onHide: function() {}
}

export default NavigationModal
