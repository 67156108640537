import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import styles from 'styles/helper'

import { useLayoutContext } from 'components/Layout/LayoutContext'

let progressBarHeight = 3 // px

const entryAnimation = (props) => keyframes`
  0%, 90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const Marker = styled.div`
  display: flex;
  align-items: center;
  height: calc(100% + ${progressBarHeight/2}px);
  position: absolute;
  top: 0;
  left: ${props => props.position}px;
`

const Title = styled.div`
  animation: ${props => props.animateEntry && css`10s ${entryAnimation(props)} ease`};
  color: white;
  cursor: pointer;
  line-height: 1.4;
  position: relative;
  top: ${progressBarHeight/2}px;
  opacity: ${props => props.hover ? 1 : 0};
  text-transform: uppercase;
  transition:
    opacity .25s ease 250ms,
    transform .15s ease;
  z-index: 2001;
  font-size: .8em;

  &:hover {
    transform: scale(1.1);
  }

  ${props => props.active && css`
    transform: scale(1.1);

    &:hover {
      transform: scale(1.2);
    }
  `};
`

const Head = styled.div`
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  transition: left .15s ease;
  width: 1px;

  &::after {
    background-color: ${props => props.upcoming ? styles.white : styles.yellow};
    border-radius: 50%;
    content: "";
    display: block;
    height: 8px;
    position: absolute;
    top: -3px;
    left: -4px;
    right: -4px;
    margin: auto;
    width: 8px;
  }
`

const getPosition = (props, time) => {
  const { duration, width } = props

  if (isNaN(time) || isNaN(duration) || isNaN(width)) return undefined
  return time * width / duration
}

const TimelineMarkers = memo((props) => {
  let { currentTime, duration, animateEntry, hover, stops } = props
  const { language } = useLayoutContext()

  if (stops.length < 2) return null

  return stops && stops.map(stop => {
    const { marker, startTime, endTime } = stop

    const current = startTime <= currentTime && currentTime <= endTime
    const upcoming = startTime > currentTime

    return (
      <Marker position={getPosition(props, startTime)}>
        <Head upcoming={upcoming} />

        <Title active={current} animateEntry={animateEntry} hover={hover} onClick={() => props.onSeek(startTime)} dangerouslySetInnerHTML={{__html: marker[language]}} />
      </Marker>
    )
  })
})

TimelineMarkers.propTypes = {
  currentTime: PropTypes.number,
  duration: PropTypes.number,
  animateEntry: PropTypes.bool,
  hover: PropTypes.bool,
  stops: PropTypes.array,
  width: PropTypes.number,
  onSeek: PropTypes.func
}

TimelineMarkers.defaultProps = {
  currentTime: undefined,
  duration: undefined,
  animateEntry: false,
  hover: false,
  stops: [],
  width: undefined,
  onSeek: function() {}
}

export default TimelineMarkers
