import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import Content from 'components/Content'
import Animation from 'components/Animation'

const ContentWrapper = styled.div`
  ${props => props.breakpoint.desktop && css`
    margin-left: 10vw;
    width: 40%;
  `}

  ${props => props.breakpoint.tablet && css`
    margin-top: 5vw;
    margin-left: 5vw;
    width: 40%;
  `}

  ${props => props.breakpoint.mobile && css`
    display: flex;
    align-items: center;
    flex: 0 0 100vw;
    padding-bottom: 15vw;
    margin-left: 0;
    width: 100vw;
  `}
`

const ContentContainer = styled.div`
  position: relative;
  margin-left: ${props => !props.breakpoint.mobile ? 0 : `1em`};
  width: 100%;
`

class HomeContent extends PureComponent {
  state = {}

  render() {
    const { breakpoint, currentTime, stops, language, languageIsChanging, view } = this.props

    return (
      <ContentWrapper breakpoint={breakpoint}>
        <ContentContainer breakpoint={breakpoint}>
          <Content breakpoint={breakpoint} currentTime={currentTime} stops={stops} language={language} />
        </ContentContainer>
      </ContentWrapper>
    )
  }

  static propTypes = {
    breakpoint: PropTypes.object,
    currentTime: PropTypes.number,
    stops: PropTypes.array
  }

  static defaultProps = {
    breakpoint: {},
    currentTime: undefined,
    stops: []
  }
}

export default HomeContent
