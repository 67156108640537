import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import PropTypes from 'prop-types'
import Reveal from 'react-reveal/Fade'
import Classnames from 'classnames'
import styled, { css, keyframes } from 'styled-components'
import styles from 'styles/helper'

import { Logo } from 'components/common/vectors'
import Arrow from 'components/elements/Arrow'
import Button from 'components/elements/Button'
import Tab from 'components/elements/Tab'

import LayoutContext from 'components/Layout/LayoutContext'
import NavigationModal from 'components/Layout/NavigationModal'

const toTop = (props) => keyframes`
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
`

const fromTop = (props) => keyframes`
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
`

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: wrap;
  height: auto;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  user-select: none;
  width: auto;
  z-index: 2002;

  ${props => props.breakpoint.desktop && css`
    padding: 1em;

    ${props => !props.animateOut ? css`
      animation: 1.5s ${fromTop(props)} ease;
    ` : css`
      animation: 3s ${toTop(props)} ease 1000ms;
    `}
  `}

  .header-logo {
    color: white;
    cursor: default;
    display: flex;
    align-items: center;
    line-height: 0;
    position: relative;
    overflow: hidden;
    white-space: nowrap;

    ${props => props.breakpoint.tablet && css`
      padding: .75em 1em;
    `}

    ${props => props.breakpoint.mobile && css`
      padding: .25em 1em;
    `}

    .logo {
      height: ${props => props.breakpoint.desktop ? `2.5em` : props.breakpoint.tablet ? `2.5em` : `2em`};
    }

    > div {
      display: inline-block;
      margin-left: .5em;

      a {
        color: white;
        text-decoration: none;
      }

      h3 {
        display: inline-block;
        font-size: .95em;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;

        span {
          color: ${styles.orange};
          cursor: pointer;
          margin-left: 1em;
        }
      }
    }
  }

  .header-tabs {
    margin-right: 10%;
    width: 35vw;

    &.header-tabs--block {
      order: 3;
      flex: 0 0 100%;
    }
  }
`
const ContactContainer = styled.div`
  transition: opacity .5s ease;

  ${props => props.isHidden && css`
    opacity: 0;
  `}
`

const MenuIconWrapper = styled.div`
  height: 1em;
  position: relative;
  width: 1em;
`

const MenuIcon = styled.div`
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: all .15s ease;
  width: 100%;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto
    transition: all .15s ease;
    width: 100%;
  }

  ${props => !props.open ? css`
    background-color: ${styles.darkBlue};

    &::before {
      background-color: ${styles.darkBlue};
      top: -.65em;
    }

    &::after {
      background-color: ${styles.darkBlue};
      bottom: -.65em;
    }
  ` : css`
    &::before {
      background-color: ${styles.orange};
      transform: rotate(45deg);
    }

    &::after {
      background-color: ${styles.orange};
      transform: rotate(-45deg);
    }
  `}
`

const ArrowWrapper = styled.div`
  display: inline-block;
  position: relative;
  padding-right: 15px;

  &::after {
    content: "";
    cursor: pointer;
    display: block;
    height: 300%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
  }
`

class Header extends PureComponent {
  state = {
    categoryIndex: undefined,
    projectTitle: undefined,
    showNavigationModal: false
  }

  componentDidMount() {
    const { categoryIndex, projectTitle } = this.context

    this.setState({categoryIndex, projectTitle})
  }

  static contextType = LayoutContext

  render() {
    const { breakpoint } = this.props
    const { categoryIndex, projectTitle, showNavigationModal } = this.state
    const { language, getTranslation } = this.context

    const category = categoryIndex === 2 ? 'highlights' : categoryIndex === 1 ? 'live_events' : 'live_action'

    return (
      <HeaderContainer breakpoint={breakpoint} animateOut={this.props.fadeOut} breakpoint={breakpoint}>
        <div className="header-logo">
          <NavLink to={language === 'en' ? `/en` : `/`}>
            <Logo className="logo" />
          </NavLink>

          <div>
            <h3>
              <NavLink to={language === 'en' ? `/en` : `/`}>{getTranslation(`project.${category}`)}</NavLink>
              {!breakpoint.mobile && <span onClick={() => this.setState({showNavigationModal: !showNavigationModal})}>{projectTitle}</span>}
            </h3>

            {breakpoint.desktop && (
              <ArrowWrapper onClick={() => this.setState({showNavigationModal: !showNavigationModal})}>
                <Arrow height={14} css={css`cursor: pointer; margin: 0 15px 0 30px; transform: rotate(90deg);`} />
              </ArrowWrapper>
            )}
          </div>
        </div>

        <Transition
          in={showNavigationModal}
          timeout={{
            exit: 250
          }}
          appear
          mountOnEnter
          unmountOnExit>
          {state => {
            return (
              // state change: exited -> entering -> entered -> exiting -> exited
              <NavigationModal
                breakpoint={breakpoint}
                hidden={state !== 'entered'}
                onHide={() => this.setState({showNavigationModal: false})} />
            )
          }}
        </Transition>

        <>
          {breakpoint.desktop && (
            <ContactContainer isHidden={this.props.ended}>
              <NavLink to={language === 'en' ? `/en/contact` : `/kontakt`}>
                <Button bordered>{getTranslation('contact')}</Button>
              </NavLink>
            </ContactContainer>
          )}

          {!breakpoint.desktop && (
            <Button breakpoint={breakpoint} accent={!showNavigationModal} inline css={css`
              border: 1px solid ${styles.orange};
              display: flex;
              align-items: center;
              justify-content: center;
              height: ${breakpoint.tablet ? css`100%` : css`2.5em`};
              margin-right: .75em;
              width: ${breakpoint.tablet ? css`3.5em` : css`2.5em`};
              z-index: 10002;
            `} onClick={() => this.setState({showNavigationModal: !showNavigationModal})}>
              <MenuIconWrapper>
                <MenuIcon open={showNavigationModal} onClick={() => this.setState({showNavigationModal: !showNavigationModal})} />
              </MenuIconWrapper>
            </Button>
          )}
        </>
      </HeaderContainer>
    )
  }

  static propTypes = {
    breakpoint: PropTypes.object
  }

  static defaultProps = {
    breakpoint: {}
  }
}

export default Header
