import React, { memo } from 'react'
import { Transition } from 'react-transition-group'
import PropTypes from 'prop-types'
import styles, { styled, css } from 'styles'

import Member from './member'

const Container = styled.div`
  ${props => props.breakpoint.desktop && css`
    flex-basis: 50%;
    margin-right: ${(100-50-25)/3}%;
  `}

  ${props => props.breakpoint.tablet && css`
    flex-basis: 58%;
    margin-right: ${(100-58-25)/3}%;
  `}

  ${props => props.breakpoint.mobile && css`
    flex-basis: 100%;
  `}
`

const Animation = styled.div`
  display: flex;
  float: right;
  overflow: hidden;
  width: 100%;

  ${props => props.breakpoint.mobile && css`
    flex-direction: column;
  `}

  ${props => props.animate && css`
    transition: width .5s ease 750ms;
    width: 0;
  `}
`

const Members = memo(props => {
  const { breakpoint, team, language, view } = props

  return (
    <Container breakpoint={breakpoint}>
      <Animation animate={breakpoint.desktop && view.exit} breakpoint={breakpoint}>
        {team.map(member => (
          <Member
            breakpoint={breakpoint}
            image={member.image}
            name={member.name}
            role={member.role[language]}
            email={member.email}
            phone={member.phone}
            view={view} />
        ))}
      </Animation>
    </Container>
  )
})

Members.propTypes = {
  breakpoint: PropTypes.object,
  team: PropTypes.array,
  language: PropTypes.string
}

Members.defaultProps = {
  breakpoint: function() {},
  team: [],
  language: undefined
}

export default Members
