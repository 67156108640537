import React, { PureComponent, createContext, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import axios from 'axios'

import { isNumber } from 'helpers/typeHelper'

const defaultValues = {
  categories: undefined,
  translations: {},
  team: []
}

const DataContext = createContext(defaultValues)

export class DataContextProvider extends PureComponent {
  state = {...defaultValues}

  componentDidMount() {
    this.getData()
  }

  getData() {
    const isLocal = isNumber(parseInt(window.location.port))
    const isStaging = window.location.hostname.includes('netlify')

    axios.get(
      isLocal
      ? '/local_data.json'
      : isStaging
        ? 'https://public.rockstick.ee/web/staging_data.json'
        : 'https://public.rockstick.ee/web/data.json'
      )
      .then(response => {
        const { categories, translations, team } = response.data

        this.setState({categories, translations, team})
      })
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    const { categories } = this.state

    return (
      <DataContext.Provider value={{
        ...this.state
      }}>
        {categories ? this.props.children : null}
      </DataContext.Provider>
    )
  }

  static propTypes = {}

  static defaultProps = {}
}

export const useDataContext = () => useContext(DataContext)

export default DataContext
