import config from 'config'
import React, { PureComponent } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import { createGlobalStyle } from 'styled-components'
import styles from 'styles/helper'

import DataContext, { DataContextProvider } from 'components/DataContext'
import LayoutContext, { LayoutContextProvider } from 'components/Layout/LayoutContext'

import Layout from 'components/Layout/Layout'
import Home from 'components/Home/Home'
import Project from 'components/Project/Project'
import Contact from 'components/Contact/Contact'

import WindowDimensionsContext, { WindowDimensionsContextProvider } from 'components/WindowDimensionsContext'
import withWindowDimensions from 'components/withWindowDimensions'

const GlobalStyles = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css?family=Comfortaa:600,700&display=swap");
  @import url("https://use.typekit.net/pmj6rqg.css");
`

const route = {
  home: {
    path: '(/|/en)',
    name: 'Home',
    Component: withWindowDimensions(Home),
    callbacks: {}
  },
  project: {
    name: 'Project',
    Component: withWindowDimensions(Project),
    callbacks: {
      enter: 'hideFullLogo',
      exit: 'showFullLogo'
    }
  },
  contact: {
    path: '(/kontakt|/en/contact)',
    name: 'Contact',
    Component: withWindowDimensions(Contact),
    callbacks: {}
  }
}

class App extends PureComponent {
  useCallback(callback, argument, context, delay) {
    if (callback && context[callback]) {
      const callbackFunction = context[callback]

      if (!delay) return callbackFunction(argument)

      return setTimeout(() => {
        callbackFunction(argument)
      }, delay)
    }

    return null
  }

  renderRoute(props) {
    const { path, name, Component, callbacks } = props

    return (
      <WindowDimensionsContext.Consumer>
        {dimensions => {
          const { breakpoint } = dimensions

          return (
            <Route key={path} exact path={path}>
              {({ history, location, match }) => {
                const timeout = breakpoint.desktop && config.enableDesktopAnimations ? 1500 : 0
                const transitionDuration = 750

                return (
                  <LayoutContext.Consumer>
                    {context => (
                      <Transition
                        in={match !== null}
                        timeout={{
                          appear: 0, // TODO: Removing this results in Home not rendering
                          enter: timeout,
                          exit: timeout + transitionDuration
                        }}
                        onEnter={() => {
                          this.useCallback(callbacks.enter, undefined, context)
                        }}
                        onEntered={() => {
                          this.useCallback('setCurrentView', {[name.toLowerCase()]: true}, context, transitionDuration)
                        }}
                        onExit={() => {
                          this.useCallback(callbacks.exit, undefined, context)
                        }}
                        onExited={() => {
                          this.useCallback('setCurrentView', {[name.toLowerCase()]: false}, context)
                        }}
                        appear // TODO: Removing this results in Home not rendering
                        mountOnEnter
                        unmountOnExit
                      >
                        {state => {
                          if (state === 'exited' || state === 'entering') return <div /> // Rendering empty element does not skip states 'exited' and 'entering' in Transition lifecycle

                          return (
                            // state change: exited -> entering -> entered -> exiting -> exited
                            <Component
                              history={history}
                              location={location}
                              currentComponent={match !== null}
                              match={match}
                              view={state} // Can not use states 'exited' and 'entering' due to mounting delay
                              transitionState={state} // Can not use states 'exited' and 'entering' due to mounting delay
                            />
                          )
                        }}
                      </Transition>
                    )}
                  </LayoutContext.Consumer>
                )
              }}
            </Route>
          )
        }}
      </WindowDimensionsContext.Consumer>
    )
  }

  render() {
    return (
      <WindowDimensionsContextProvider>
        <GlobalStyles />

        <Router>
          <DataContextProvider>
            <DataContext.Consumer>
              {context => (
                <LayoutContextProvider dataContext={context}>
                  <Layout>
                    <React.Fragment>
                      {this.renderRoute(route.home)}
                      {this.renderRoute(route.contact)}

                      {context.categories.map(category => {
                        return category.projects.map(project => {
                          const { slug } = project

                          return Object.keys(slug).map(language => {
                            const props = {
                              ...route.project,
                              path: `${language === 'en' ? `/en/` : `/`}${slug[language]}`
                            }

                            return this.renderRoute(props)
                          })
                        })
                      })}
                    </React.Fragment>
                  </Layout>
                </LayoutContextProvider>
              )}
            </DataContext.Consumer>
          </DataContextProvider>
        </Router>
      </WindowDimensionsContextProvider>
    )
  }
}

export default App
