import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styles, { styled, css } from 'styles'

const InputElement = styled.input`
  background-color: ${styles.darkerBlue};
  border: 1px solid ${styles.white};
  border-radius: 0;
  box-sizing: border-box;
  color: ${styles.white};
  font-size: .8em;
  outline: none;
  padding: 1em;
  margin-bottom: .75vw;
  width: 100%;

  &::placeholder {
    color: ${styles.white};
  }

  ${props => props.value === null && css`
    border-color: ${styles.red};
  `}

  ${props => props.css}
`

const Input = memo((props) => {
  return (
    <InputElement
      type={props.type}
      css={props.css}
      placeholder={props.placeholder}
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)} />
  )
})

Input.propTypes = {
  css: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

Input.defaultProps = {
  css: '',
  placeholder: '',
  type: 'text',
  value: '',
  onChange: function() {}
}

export default Input
