import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Classnames from 'classnames'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import VideoContext from './VideoContext'
import GraphicsImageElement from './GraphicsImageElement'
import GraphicsFullImageElement from './GraphicsFullImageElement'

const GraphicsWrapper = styled.div`
  height: ${props => props.height}px;
  position: fixed;
  top: ${props => props.top}px;
  left: 0;
  right: 0;
  margin: auto;
  width: calc(100% - 8em);
  z-index: 1001;

  ${props => props.animate && css`
    transition:
      height .25s ease 250ms;
  `}

  ${props => props.breakpoint.tablet && css`
    height: calc(100% - 7em);
    top: 3em;
    bottom: 2em;
    width: calc(100% - 4em);
  `}
`

const GraphicsContainer = styled.div`
  backdrop-filter: blur(10px);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  color: white;
  padding: 10px 40px 10px 60px;
  position: absolute;
  top: ${props => props.position.includes('top') ? 0 : `auto`};
  left: ${props => props.position.includes('left') ? 0 : `auto`};
  right: ${props => props.position.includes('right') ? 0 : `auto`};
  bottom: ${props => props.position.includes('bottom') ? 0 : `auto`};
  transition: opacity .5s ease;
  opacity: ${props => props.visible ? 1 : 0};
  z-index: ${props => props.visible ? 1 : -1};

  ${props => props.backgroundImage && css`
    background-image: url(${props.backgroundImage});
  ` || css`
    background-color: rgba(${styles.rgb.blue},.6);
  `}
`

const GraphicsImageContainer = styled.div`
  padding: 0;
  position: absolute;
  top: ${props => props.position.includes('top') || (!props.position.includes('top') && !props.position.includes('bottom')) ? 0 : `auto`};
  left: ${props => props.position.includes('left') || (!props.position.includes('left') && !props.position.includes('right')) ? 0 : `auto`};
  right: ${props => props.position.includes('right') || (!props.position.includes('left') && !props.position.includes('right')) ? 0 : `auto`};
  bottom: ${props => props.position.includes('bottom') || (!props.position.includes('top') && !props.position.includes('bottom')) ? 0 : `auto`};
  margin: auto;
  transition: opacity .5s ease;
  opacity: ${props => props.visible ? 1 : 0};
  width: ${props => props.width}%;
  z-index: ${props => props.visible ? 1 : -1};
`

class Graphics extends PureComponent {
  state = {
    height: undefined,
    resizeTimeout: undefined,
    width: undefined
  }

  resize = this.resize.bind(this)

  static contextType = VideoContext

  componentDidMount() {
    this.setDimensions()

    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize() {
    let { resizeTimeout } = this.state

    if (resizeTimeout) clearTimeout(resizeTimeout)

    resizeTimeout = setTimeout(() => {
      this.setState({resizeTimeout: null})
    }, 750)

    this.setState({resizeTimeout})
    this.setDimensions()
  }

  setDimensions() {
    const height = window.innerHeight
    const width = window.innerWidth

    if (height && width) this.setState({height, width})
  }

  render() {
    const { breakpoint, items } = this.props
    const { height, resizeTimeout, width } = this.state
    const { currentTime, timelineHidden } = this.context

    return (
      <GraphicsWrapper
        animate={!resizeTimeout}
        breakpoint={breakpoint}
        height={!timelineHidden ? height - (width/8) : height - (width/10)}
        top={(width/8)*0.5}>
        {items.map(item => {
          const { startTime, endTime, type } = item

          const itemInTime = startTime < currentTime && currentTime < endTime

          if (type === 'text') {
            const { backgroundImage, descriptionHtml, position } = item

            return (
              <GraphicsContainer
                backgroundImage={backgroundImage}
                position={position}
                visible={itemInTime}
                dangerouslySetInnerHTML={{__html: descriptionHtml}} />
            )
          }

          if (type === 'image') {
            const { image, position, width } = item

            return (
              <GraphicsImageElement
                itemInTime={itemInTime}
                image={image}
                position={position}
                width={width} />
            )
          }

          if (type === 'full-image') {
            const { image, position } = item

            return (
              <GraphicsFullImageElement
                itemInTime={itemInTime}
                image={image}
                position={position} />
            )
          }
        })}
      </GraphicsWrapper>
    )
  }

  static propTypes = {
    items: PropTypes.array
  }

  static defaultProps = {
    items: []
  }
}

export default Graphics
