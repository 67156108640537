import config from 'config'
import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

import LayoutContext from 'components/Layout/LayoutContext'

import VideoContext, { VideoContextProvider } from 'components/Project/VideoContext'
import TransitionAnimation from 'components/TransitionAnimation'
import Loading from './Loading'
import VideoContainer from './VideoContainer'

class Project extends PureComponent {
  state = {
    fallbackImagePercentageLoaded: 0,
    project: undefined,
    nextProject: undefined,
    showLoading: true,
    visible: false,
    waitingForVideo: false
  }

  static contextType = LayoutContext

  componentDidMount() {
    const { location, view } = this.props

    if (location.pathname) {
      let slug = location.pathname.replace(location.pathname.length > 3 ? 'en/' : '/en', '').replace('/', '')

      if (slug) this.setProject(slug)
    }
  }

  setProject(slug) {
    const { breakpoint } = this.props
    const { categories, language, setBackgroundImage, setCategoryIndex, setProjectTitle } = this.context
    let project = undefined
    let nextProject = undefined

    {categories.map((category, categoryIndex) => {
      const projectIndex = category.projects.map(project => project.slug && project.slug[language]).indexOf(slug)

      if (projectIndex !== -1) {
        setCategoryIndex(categoryIndex)

        project = category.projects[projectIndex]

        setProjectTitle(project.title[language])
        if (!breakpoint.mobile) setBackgroundImage(project.backgroundImage)

        nextProject = category.projects[projectIndex+1]
        if (!nextProject) nextProject = categories[categoryIndex+1] && categories[categoryIndex+1].projects[0]
        if (!nextProject) nextProject = categories[0] && categories[0].projects[0]

        if (nextProject) project = {...project, nextUrl: `${language === 'en' ? `/en/` : `/`}${nextProject.slug[language]}`}
      }
    })}

    this.setState({project})
  }

  render() {
    const { breakpoint, view, width } = this.props
    const { fallbackImagePercentageLoaded, project, showLoading, visible, waitingForVideo } = this.state
    const { setBackgroundImage, animateProject } = this.context

    if (!project || !project.video) return null

    return (
      <VideoContextProvider>
        <VideoContext.Consumer>
          {context => {
            return (
              <Transition
                in={showLoading || (waitingForVideo && !context.isPlaying)}
                timeout={750}
                onExiting={() => {
                  if (breakpoint.mobile) setBackgroundImage(null)

                  this.setState({visible: true})
                }}
                onEntered={() => this.setState({showLoading: false, waitingForVideo: true})}
                onExited={() => this.setState({waitingForVideo: false})}
                appear
                unmountOnExit>
                {state => {
                  return (
                    // state change: exited -> entering -> entered -> exiting -> exited
                    <TransitionAnimation
                      animateIn={((breakpoint.desktop && !config.enableDesktopAnimations) || breakpoint.mobile || animateProject) && state.includes('enter')}
                      animateOut={((breakpoint.desktop && !config.enableDesktopAnimations) || breakpoint.mobile || animateProject) && state.includes('exit')}
                      overlay={true}>
                      {state !== 'entering' && <Loading loaded={state === 'exiting'} animate={state === 'exiting'} />}
                    </TransitionAnimation>
                  )
                }}
              </Transition>
            )
          }}
        </VideoContext.Consumer>

        <VideoContainer {...this.props} project={project} view={view} visible={visible} width={width} />
      </VideoContextProvider>
    )
  }

  static propTypes = {
    width: PropTypes.number // TODO: width is not set
  }

  static defaultProps = {
    width: undefined // TODO: width is not set
  }
}

export default Project
