import React, { PureComponent, createContext, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import axios from 'axios'

import { isNumber } from 'helpers/typeHelper'

const defaultValues = {
  language: 'et'
}

const LayoutContext = createContext(defaultValues)

export class LayoutContextProviderWithRouter extends PureComponent {
  state = {
    ...defaultValues,
    backgroundImage: undefined,
    categoryIndex: 0,
    currentView: {
      home: false,
      project: false,
      contact: false
    },
    view: {},
    projectTitle: false,
    isFixedLayout: false,
    isFullLogo: true,
    languageIsChanging: undefined,
    animateProject: false
  }

  navigateBrowser = this.navigateBrowser.bind(this)

  componentDidMount() {
    this.getData()

    const { location } = this.props

    if (location.pathname.includes('/en')) this.setState({language: 'en'})

    window.addEventListener('popstate', this.navigateBrowser)
  }

  getData() {
    const isLocal = isNumber(parseInt(window.location.port))
    const isStaging = window.location.hostname.includes('netlify')

    axios.get(
      isLocal
      ? '/local_data.json'
      : isStaging
        ? 'https://public.rockstick.ee/web/staging_data.json'
        : 'https://public.rockstick.ee/web/data.json'
      )
      .then(response => {
        const { categories, translations, team } = response.data

        this.setState({categories, translations, team})
      })
      .catch(error => {
        console.log(error)
      })
  }

  navigateBrowser() {
    this.setState({animateProject: true})
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    const { currentView, animateProject } = this.state

    if (location.pathname !== prevProps.location.pathname) {
      if (prevProps.location.pathname !== '/' && prevProps.location.pathname !== '/en') {
        if (currentView.project) this.setState({animateProject: true})
      } else {
        if (animateProject === true) this.setState({animateProject: false})
      }
    }

    this.didCurrentViewUpdate(prevProps, prevState)
  }

  didCurrentViewUpdate(prevProps, prevState) {
    const { currentView } = this.state

    if (currentView.home && currentView.home !== prevState.currentView.home) this.setState({isFixedLayout: false})
    if (currentView.project && currentView.project !== prevState.currentView.project) this.setState({isFixedLayout: true})
    if (currentView.contact && currentView.contact !== prevState.currentView.contact) this.setState({isFixedLayout: false})
  }

  setLanguage = (language, delay = 50) => {
    const { history } = this.props
    const { languageIsChanging } = this.state

    if (!languageIsChanging) {
      setTimeout(() => {
        setTimeout(() => {
          this.setState({languageIsChanging: null})
        }, delay)

        this.setState({language})
      }, delay)

      this.setState({languageIsChanging: true})
    }
  }

  getTranslation = (string) => {
    const { language, translations } = this.state

    return translations && translations[string] && translations[string][language]
  }

  render() {
    const { categories } = this.state

    return (
      <LayoutContext.Provider value={{
        ...this.props.dataContext,
        ...this.state,
        setBackgroundImage: (backgroundImage) => this.setState({backgroundImage}),
        setCategoryIndex: (categoryIndex) => this.setState({categoryIndex}),
        setCurrentView: (view) => this.setState({currentView: {...this.state.currentView, ...view}}),
        setFixedLayout: (isFixedLayout) => this.setState({isFixedLayout}),
        setLanguage: this.setLanguage,
        setProjectTitle: (projectTitle) => this.setState({projectTitle}),
        setView: (view) => this.setState({view}),
        showFullLogo: () => this.setState({isFullLogo: true}),
        hideFullLogo: () => this.setState({isFullLogo: false}),
        getTranslation: this.getTranslation
      }}>
        {categories ? this.props.children : null}
      </LayoutContext.Provider>
    )
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  static defaultProps = {
    match: {},
    location: {},
    history: {}
  }
}

export const LayoutContextProvider = withRouter(LayoutContextProviderWithRouter)

export const useLayoutContext = () => useContext(LayoutContext)

export const withView = WrappedComponent => {
  class Wrapper extends PureComponent {
    state = {
      animating: true
    }

    static contextType = LayoutContext

    componentDidMount() {
      const { transitionState } = this.props
      const { animating } = this.state
      const { setView } = this.context

      setTimeout(() => {
        this.setState({animating: false})
      }, 750)

      const view = {
        state: transitionState,
        entering: transitionState === 'entered' && animating,
        entered: transitionState === 'entered',
        exiting: transitionState !== 'entered'
      }

      setView(view)
    }

    componentDidUpdate(prevProps, prevState) {
      const { transitionState } = this.props
      const { animating } = this.state

      if ((transitionState !== prevProps.transitionState) || (animating !== prevState.animating)) {
        const { setView } = this.context

        const view = {
          state: transitionState,
          entering: transitionState === 'entered' && animating,
          entered: transitionState === 'entered' && !animating,
          exiting: transitionState !== 'entered'
        }

        setView(view)
      }
    }

    render() {
      return (
        <WrappedComponent {...this.props} />
      )
    }
  }

  return Wrapper
}

export default LayoutContext
