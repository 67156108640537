import React, { PureComponent, createContext, useContext } from 'react'
import PropTypes from 'prop-types'

const defaultValues = {
  project: undefined,
  currentTime: undefined,
  duration: undefined,
  play: false,
  pause: false,
  seeking: true,
  seekingTime: undefined,
  timelineHidden: false,
  ended: false,
  isPlaying: false
}

const VideoContext = createContext({defaultValues})

export class VideoContextProvider extends PureComponent {
  state = {...defaultValues}

  componentDidUpdate(prevProps, prevState) {
    const { currentTime } = this.state

    if (!isNaN(currentTime) && currentTime > 0 && currentTime !== prevState.currentTime) {
      this.setState({isPlaying: true})
    }
  }

  render() {
    const handlers = {
      setConfig: (config) => this.setState({config}),
      setProject: (project) => this.setState({project}),
      setCurrentTime: (currentTime) => this.setState({currentTime}),
      setDuration: (duration) => this.setState({duration}),
      setPlay: (state) => this.setState({play: state}),
      setPause: (state) => this.setState({pause: state}),
      setSeekingTime: (time) => {
        if (!isNaN(time)) {
          this.setState({currentTime: time, seekingTime: time})
        } else {
          this.setState({seekingTime: undefined})
        }
      },
      setTimelineHidden: (timelineHidden) => this.setState({timelineHidden}),
      setEnded: (ended) => this.setState({ended})
    }

    return (
      <VideoContext.Provider value={{...this.state, ...handlers}}>
        {this.props.children}
      </VideoContext.Provider>
    )
  }

  static propTypes = {}

  static defaultProps = {}
}

export const useVideoContext = () => useContext(VideoContext)

export default VideoContext
