import React, { memo } from 'react'
import PropTypes from 'prop-types'

import './Loader.scss'

export const Loader = memo(props => {
  const { percentage } = props

  return (
    <div className="loader-wrapper">
      <div className="loader" />
    </div>
  )
})

Loader.propTypes = {}

Loader.defaultProps = {}

export default Loader
