import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styles from 'styles/helper'

const SeekArea = styled.div`
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1999;
`

const TimelineSeekArea = memo((props) => {
  return (
    <SeekArea onClick={() => props.onClick()} />
  )
})

TimelineSeekArea.propTypes = {}

TimelineSeekArea.defaultProps = {}

export default TimelineSeekArea
